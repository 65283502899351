import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { ProductInput as Input } from 'Common/components/product';
import { useAction } from 'Common/hooks';
import { product, order } from '~features';
import * as GTM from 'Common/constants/gtm';

export default function ProductInput({ isUserAuthenticated, checkoutPageLink, ...props }) {
    const productInfo = useSelector(product.selectors.getProduct);
    const quantity = useSelector(product.selectors.getQuantity);
    const currentItemStatus = useSelector(product.selectors.getCurrentItemStatus);
    const currentChildren = useSelector(product.selectors.getCurrentChildren);
    const onQuantityChange = useAction(product.actions.selectQuantity);
    const orderLastUpdate = useSelector(order.selectors.getLastUpdate);
    const onAddItemToCart = useAction(order.actions.addItem);
    const itemCodes = React.useMemo(() => productInfo?.Children?.map((c) => c.Code) ?? [currentItemStatus.Code], [
        currentItemStatus,
        productInfo,
    ]);

    return (
        <Input
            {...props}
            isUserAuthenticated={isUserAuthenticated}
            onQuantityChange={onQuantityChange}
            quantity={quantity}
            checkoutPageLink={checkoutPageLink}
            currentChildren={currentChildren}
            onAddToCart={onAddItemToCart}
            orderLastUpdate={orderLastUpdate}
            currentItemStatus={currentItemStatus}
            productInfo={productInfo}
            gtmListValue={GTM.TAGS.PDP}
            itemCodes={itemCodes}
        />
    );
}

ProductInput.propTypes = {
    isUserAuthenticated: px.bool,
    checkoutPageLink: px.string,
};
