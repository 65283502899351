import { Api } from 'Common/utils';
import { getCreditCardPaymentFromForm } from '../order/api';

// addresses

export function getCustomerAddresses() {
    return Api.GET('/customer/getCustomerAddresses');
}

export function saveCustomerAddress(address) {
    return Api.PUT(
        '/customer/saveAddress',
        {
            ...address,
            IsShipping: true,
        },
        { safe: false }
    );
}

export function deleteCustomerAddress(id) {
    return Api.DELETE(`/customer/deleteAddress`, `id=${id}`, { safe: false });
}

// gift cards

export function getCustomerGiftCards() {
    return Api.GET('/customer/getCustomerGiftCards');
}

export function deleteCustomerGiftCard(id) {
    return Api.DELETE(`/customer/deleteGiftCard/${id}`);
}

export function saveCustomerGiftCard(card) {
    return Api.PUT('/customer/saveGiftCard', {
        ...card,
    });
}

// payment methods

export function getCustomerPaymentMethods() {
    return Api.GET('/customer/GetCustomerCreditCards');
}

export function deleteCustomerPaymentMethod(id) {
    return Api.DELETE(`/customer/deleteCreditCard/${id}`);
}

export async function addCustomerPaymentMethod(data) {
    const response = await getCreditCardPaymentFromForm(data);

    if (response?.success) {
        return Api.POST(`/customer/addCreditCard`, response.creditCard, { safe: false });
    } else {
        return response;
    }
}

export function setDefaultCustomerPaymentMethod(id) {
    return Api.PUT(`/customer/markCreditCardAsDefault/${id}`);
}

// order history

export function getOrderHistoryByEmail(email, index, orderCount) {
    return Api.GET(`/order/GetOrderHistoryForEmail?orderEmail=${email}&startIndex=${index}&batchSize=${orderCount}`);
}

export function queryOrder(email, orderNumber) {
    return Api.POST(`/order/QueryOrder`, {
        OrderEmail: email,
        OrderNumber: orderNumber,
    });
}

export function submitReturnRequest(returnRequest) {
    return Api.POST('/order/SubmitReturnRequest', {
        ...returnRequest,
    });
}

// Notifications
export function getCustomerNotificationSubscriptionList() {
    return Api.GET('/notificationsubscription/GetCurrentUserNotificationSubscriptions');
}

export function unsubscribeNotificationSubscriptionById(id) {
    return Api.POST('/notificationsubscription/UnsubscribeById', {
        id,
    });
}

export function subscribe(email, code, token = null) {
    return Api.POST('/notificationsubscription/subscribe', {
        email,
        NotificationId: code,
        NotificationType: 'BackInStock',
        token,
    });
}
