import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Product } from 'Common/types';
import { useBEM, useTranslation, useViewport } from 'Common/hooks';
import { Translation } from 'Common/components/localization';
import { Icon, ImageModal } from 'Common/components/ui';
import ProductListTable from './ProductListTable';
import ProductDetailCard from './ProductDetailCard';
import ProductInput from './ProductInput';

export default function ProductListView({
    checkoutPageLink,
    className,
    googleRecaptchaV3Sitekey,
    gtmListValue,
    hideTitle = false,
    inputStatusTooltip,
    isUserAuthenticated,
    noBuyNow = false,
    NotifyMe,
    onAddItemToCart,
    onAddMultipleToCart,
    onClose,
    overrideIsMobile,
    productInfo,
    showVariantImages = false,
    singleFacet = null,
    stopPropagation = false,
    stretchBody = false,
    useRecaptcha,
    variants = [],
}) {
    const [showModal, setShowModal] = useState(false);
    const [showModalCarousel, setShowModalCarousel] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [variantMedia, setVariantMedia] = useState([]);
    const viewport = useViewport();
    const [b, em] = useBEM('ProductListView', className);
    const [qtyMap, setQtyMap] = useState({});
    const lblClose = useTranslation('Commerce.CatalogNode.QuickView.Close');
    const onQtyChange = useCallback(
        (qty, code) => {
            setQtyMap({ ...qtyMap, [code]: qty });
        },
        [qtyMap]
    );

    const itemCodes = useMemo(() => variants?.map((v) => v.Code), [variants]);
    const totalQty = useMemo(() => Object.values(qtyMap).reduce((sum, x) => sum + x, 0), [qtyMap]);
    const qtyChanger = useCallback((code) => (qty) => onQtyChange(qty, code), [onQtyChange]);
    const onTableAddToCart = useCallback(
        (_1, _2, opts) => onAddMultipleToCart(qtyMap, opts),
        [onAddMultipleToCart, qtyMap]
    );

    const stockStatus = useMemo(
        () => ({
            code: productInfo.Code,
            statusDisplayName: productInfo.StockStatusLabel,
            hasStock: productInfo.StockStatus === 'InStock',
        }),
        [productInfo]
    );

    const props = useMemo(
        () => ({ checkoutPageLink, isUserAuthenticated, gtmListValue }),
        [checkoutPageLink, isUserAuthenticated, gtmListValue]
    );

    const onClick = useMemo(
        () =>
            stopPropagation
                ? (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                  }
                : undefined,
        [stopPropagation]
    );

    const getName = useCallback(
        (v) => {
            if (singleFacet) {
                const f =
                    productInfo.Options?.DetailGridAttributeName ||
                    productInfo.Facets.find((a) => a.Attribute === singleFacet)?.Attribute ||
                    productInfo.Facets[0]?.Attribute;

                const values = v.Attributes.find((a) => a.AttributeName === f)?.Values || [v.Code];

                return values[0];
            }

            return v.Code;
        },
        [singleFacet, productInfo]
    );

    useEffect(() => {
        //reset quantities for each product passed in
        setQtyMap({});
    }, [productInfo.Code]);

    const isMobile = useMemo(() => overrideIsMobile ?? viewport.is.mobile, [viewport, overrideIsMobile]);

    const closeModal = useCallback(() => setShowModal(false), []);

    const setShowModalSlider = useCallback(() => setShowModalCarousel(true), []);

    const onClickView = useCallback((vM) => {
        setVariantMedia(vM);
        setModalContent({
            type: vM[0].FileType,
            src: vM[0].Url,
            isMobile: isMobile,
            selectedItem: vM[0],
        });
        setShowModal(true);
    }, []);

    return (
        <div className={cx(b, 'row')} onClick={onClick}>
            {hideTitle ? null : (
                <div className={cx(em('title'), 'col-12 p-0')}>
                    <div>
                        <Translation id="Commerce.Product.Table.Choose.Label" />
                    </div>
                    {onClose ? (
                        <button className="btn" role="close" aria-label={lblClose} onClick={onClose}>
                            <Icon name="times-outlined" />
                        </button>
                    ) : null}
                </div>
            )}
            {isMobile ? (
                <div className={cx(em('list'), 'col-12')}>
                    {variants?.map((variant) => (
                        <ProductDetailCard
                            {...props}
                            className={em('list__card')}
                            collapseDisplayOnlyFacets
                            expandable
                            facets={productInfo.Facets}
                            inputStatusTooltip={inputStatusTooltip}
                            key={`list__${variant.Code}`}
                            noBuyNow={noBuyNow}
                            NotifyMe={NotifyMe}
                            notifyProps={{ googleRecaptchaV3Sitekey, useRecaptcha }}
                            onAddToCart={onAddItemToCart}
                            onClickView={onClickView}
                            onQuantityChange={qtyChanger(variant.Code)}
                            quantity={qtyMap[variant.Code]}
                            showVariantImages={showVariantImages}
                            startExpanded
                            variant={variant}
                        />
                    ))}
                </div>
            ) : variants?.length ? (
                <>
                    <ProductListTable
                        className={cx(em('table'), 'col-12 px-0')}
                        facets={singleFacet ? [] : productInfo.Facets}
                        getName={getName}
                        NotifyMe={NotifyMe}
                        notifyProps={{ googleRecaptchaV3Sitekey, useRecaptcha }}
                        onClickView={onClickView}
                        onQuantityChange={onQtyChange}
                        quantities={qtyMap}
                        showVariantImages={showVariantImages}
                        stretchBody={stretchBody}
                        variants={variants}
                    />
                    <ProductInput
                        className={em('input')}
                        {...props}
                        quantity={totalQty}
                        onAddToCart={onTableAddToCart}
                        currentItemStatus={stockStatus}
                        productInfo={productInfo}
                        currentChildren={[productInfo]}
                        noBuyNow={noBuyNow}
                        itemCodes={itemCodes}
                        statusTooltip={inputStatusTooltip}
                        hideWhenOOS
                    />
                </>
            ) : (
                <ProductInput
                    className={em('input')}
                    {...props}
                    currentItemStatus={stockStatus}
                    productInfo={productInfo}
                    currentChildren={[]}
                    statusTooltip={inputStatusTooltip}
                    noBuyNow
                    hideWhenOOS
                />
            )}
            <div className="ProductGallery">
                <ImageModal
                    className="ProductGallery__Modal"
                    closeModal={closeModal}
                    isMobile={isMobile}
                    items={variantMedia}
                    modalContent={modalContent}
                    modalSize="xl"
                    setModalContent={setModalContent}
                    setShowModalSlider={setShowModalSlider}
                    showModal={showModal}
                    showCarousel={showModalCarousel}
                />
            </div>
        </div>
    );
}

ProductListView.propTypes = {
    checkoutPageLink: px.string,
    className: px.string,
    googleRecaptchaV3Sitekey: px.string,
    gtmListValue: px.string,
    hideTitle: px.bool,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    isUserAuthenticated: px.bool,
    noBuyNow: px.bool,
    NotifyMe: px.elementType,
    onAddItemToCart: px.func,
    onAddMultipleToCart: px.func,
    onClose: px.func,
    overrideIsMobile: px.bool,
    productInfo: Product,
    quickviewRef: px.objectOf(px.any),
    showVariantImages: px.bool,
    singleFacet: px.string,
    stopPropagation: px.bool,
    stretchBody: px.bool,
    useRecaptcha: px.bool,
    variants: px.arrayOf(Product),
};
