import React from 'react';
import px from 'prop-types';
import { Url } from 'Common/utils';
import { FooterLocationSearch as SearchComponent } from 'Common/components/locator';
import { useTranslation } from 'Common/hooks';

export default function FooterLocationSearch({ apiKey, region = 'us', storeLocatorPageUrl }) {
    const [value, setValue] = React.useState('');
    const submitLbl = useTranslation('Footer.Store.Locator.SubmitButton.Label');

    const onSubmit = React.useCallback(
        (location) => {
            const url = new Url(storeLocatorPageUrl);

            if (location?.lat) {
                url.addParam('lat', location.lat)
                    .addParam('lng', location.lng)
                    .addParam('addr', location.fullAddress ?? location.address);
            }
            window.location = url.href;
        },
        [storeLocatorPageUrl]
    );

    const onChange = React.useCallback((e) => setValue(e.target.value), []);

    const onPlaceChange = React.useCallback(
        (loc) => {
            if (loc) {
                setValue(loc.fullAddress);
                onSubmit(loc);
            } else {
                setValue('');
            }
        },
        [onSubmit]
    );

    const onClearInput = React.useCallback(() => setValue(''), []);

    return (
        <>
            <SearchComponent
                className="input__footer-store-locator-autocomplete"
                onPlaceChange={onPlaceChange}
                onInput={onChange}
                onChange={onChange}
                onClearInput={onClearInput}
                value={value}
                apiKey={apiKey}
                region={region}
            />
            <button
                onClick={onSubmit}
                type="button"
                className="btn px-1"
                style={{ fontSize: '16px' }}
                disabled={!value}
                aria-label={submitLbl}
            >
                <i className="fa fa-arrow-right" />
            </button>
        </>
    );
}

FooterLocationSearch.propTypes = {
    apiKey: px.string.isRequired,
    region: px.string,
    storeLocatorPageUrl: px.string.isRequired,
};
