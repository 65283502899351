import { createSelector } from 'reselect';
import { getStringMap } from '../localization/selectors';
import { GTM as gtmUtil } from 'Common/utils';
import * as GTM from 'Common/constants/gtm';

const MKT_BADGE_NEW = 'Commerce.MarketingBadge.New';

const getNewBadgeStr = createSelector(getStringMap, (strings = {}) => strings[MKT_BADGE_NEW] || '');

function getAttribute(child, attr) {
    return child.Attributes.find((a) => a.AttributeName === attr);
}

const productIsForSale = (product) => product.StockStatus !== 'DisplayOnly';

export const getItem = (state) => state.product;

export const getItemDetails = createSelector(getItem, (product = {}) => product.details);

export const getSelectedCode = createSelector(getItem, (product = {}) => product.selectedCode);

export const getProductStore = createSelector(getItem, (product = {}) => product.byId);

export const getProductById = createSelector(
    (_, arg) => arg,
    getProductStore,
    (id, store = {}) => store[id]
);

export const getSelectedProduct = createSelector(
    getSelectedCode,
    getProductStore,
    (code, store = {}) => (code && store[code]) || undefined
);

export const getQuantity = createSelector(getItem, (product = {}) => product.quantity);

export const getRelatedProducts = createSelector(getItemDetails, (details = {}) =>
    details.CatalogRelatedProducts?.slice(0, 3)
);

export const getEpiRecommedationAlternativeProducts = createSelector(
    getItemDetails,
    (details = {}) => details.EpiRecommendationAlternativeProducts
);

export const getEpiRecommedationCrossSellProducts = createSelector(
    getItemDetails,
    (details = {}) => details.EpiRecommendationCrossSellProducts
);

export const getRootSelectedChildren = createSelector(getItem, (product = {}) => product.selectedChildren);

export const getRootSelectedChild = createSelector(getItem, (product = {}) => product.selectedChild);

export const getProduct = createSelector(getItemDetails, (details = {}) => details.Product);

export const getProductUrl = createSelector(getProduct, (product = {}) => product?.ContentUrl || product?.ContentLink);

export const getFacets = createSelector(getProduct, (product = {}) => product.Facets);

// A mapping of each facet value and its stock status determined by a variant existing that is in stock and contains the facet/facet value in its variants.
export const getFacetValueStockStatuses = createSelector(getProduct, (product = {}) => {
    return product.Facets?.reduce((mapping, facet) => {
        return {
            ...mapping,
            [facet.Attribute]: facet.FacetValues.reduce((values, value) => {
                return {
                    ...values,
                    [value.Value]: product.Children.reduce((match, child) => {
                        if (match) return match;
                        return (
                            child.Attributes.find((a) => a.AttributeName === facet.Attribute)?.Values?.includes(
                                value.Value
                            ) && child.StockStatus === 'InStock'
                        );
                    }, false),
                };
            }, {}),
        };
    }, {});
});

export const getFeatures = createSelector(getProduct, (product = {}) => product.Features || undefined);

export const getOptions = createSelector(getProduct, (product = {}) => product.Options);

export const getSelectedAttributes = createSelector(getFacets, (facets = []) =>
    facets
        .map((f) => ({
            name: f.Attribute,
            values: f.FacetValues.map((v) => v.Selected && v.Value).filter(Boolean),
            controlType: f.SelectorControlType,
        }))
        .filter((a) => a.values.length)
);

export const getChildren = createSelector(getProduct, (product = {}) => product.Children);

export const getInStockChildren = createSelector(getProduct, (product = {}) =>
    product?.Children?.filter((a) => a.StockStatus === 'InStock')
);

export const getSelectedChild = createSelector(
    getRootSelectedChildren,
    getRootSelectedChild,
    getChildren,
    getProduct,
    (selectedChildren, selectedChild = {}, children = [], product = {}) => {
        if (product.Facets?.length > 0 && children?.length === 1) {
            return children[0];
        } else {
            return (selectedChildren || selectedChild) && children.find((c) => c.Code === selectedChild.Code);
        }
    }
);

// Current children are made up of variants that contain attribute values currently selected by the user.
export const getCurrentChildren = createSelector(
    getChildren,
    getSelectedAttributes,
    getSelectedChild,
    (children = [], attributes = [], selectedChild = null) =>
        selectedChild
            ? [selectedChild]
            : children.filter((child) =>
                  attributes.reduce((match, { name, values }) => {
                      if (!match) return false;

                      const attribute = child.Attributes.find((a) => a.AttributeName === name);

                      return (
                          attribute?.Values &&
                          values.reduce((submatch, v) => submatch && attribute.Values.includes(v), true)
                      );
                  }, true)
              )
);

export const getValidFacetCombinations = createSelector(getProduct, getChildren, (product = {}, children = []) => {
    return children.map((c) => {
        return product.Facets.reduce((combination, f) => {
            const facetName = f.Attribute;
            const attribute = c.Attributes?.find((a) => a.AttributeName === facetName);
            const facetValue = attribute?.Values?.length ? attribute.Values[0] : null;

            return facetValue ? { ...combination, [facetName]: facetValue } : combination;
        }, {});
    });
});

export const getFilteredFacetCombinations = createSelector(
    getValidFacetCombinations,
    getSelectedAttributes,
    getFacets,
    (validCombinations = [], currentAttributes = [], facets = []) => {
        if (currentAttributes.length === 0 || facets.length === 1) return validCombinations;
        return validCombinations.filter((c) => {
            return currentAttributes.filter((a) => a.values.includes(c[a.name])).length === currentAttributes.length;
        });
    }
);

export const getDisabledFacetValues = createSelector(
    getChildren,
    getFilteredFacetCombinations,
    getFacets,
    getFacetValueStockStatuses,
    (children = [], filteredCombinations = [], facets = [], facetValueStockStatus = {}) =>
        facets.reduce(
            (obj, facet) => ({
                ...obj,
                [facet.Attribute]: children.reduce(
                    (match, child) => match || getAttribute(child, facet.Attribute),
                    false
                )
                    ? facet.FacetValues.reduce(
                          (valueObj, value) => ({
                              ...valueObj,
                              [value.Value]: {
                                  isDisabled:
                                      filteredCombinations.filter((c) => c[facet.Attribute] === value.Value).length ==
                                      0,
                                  hasStock: facetValueStockStatus[facet.Attribute][value.Value],
                                  isValid: !!children.find((c) =>
                                      c.Attributes.find((a) => a.AttributeName === facet.Attribute)?.Values.includes(
                                          value.Value
                                      )
                                  ),
                              },
                          }),
                          {}
                      )
                    : true,
            }),
            {}
        )
);

export const getCurrentFacets = createSelector(getFacets, getDisabledFacetValues, (facets = [], disabledMap = {}) =>
    facets.map((facet) => ({
        ...facet,
        FacetValues: facet.FacetValues?.filter((value) =>
            disabledMap[facet.Attribute] ? !disabledMap[facet.Attribute][value.Value] : false
        ),
    }))
);

export const getMedia = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return selected?.GalleryMedia;
        }
        if (
            (product.Facets?.length > 0 && children?.length === 1) ||
            attributes?.find((a) => a.controlType.includes('color-'))
        ) {
            return children[0]?.GalleryMedia;
        }
        return product.GalleryMedia;
    }
);

export const getProductMedia = createSelector(getProduct, (product = {}) => {
    return product.GalleryMedia;
});

export const getProductMainMedia = createSelector(
    getItem,
    getProduct,
    getProductMedia,
    (productState = {}, product = {}, currentMedia = []) => {
        if (productState.selectedMedia && currentMedia.find((m) => m.Url === productState.selectedMedia.Url)) {
            return productState.selectedMedia;
        }
        if (currentMedia.length) {
            return (
                currentMedia.find((media) => media.MediaGroup === 'Primary') ||
                currentMedia.find((media) => media.IsDefault) ||
                currentMedia.find((media) => media.Url === product.DefaultImageUrl) ||
                currentMedia[0]
            );
        }

        return product.GalleryMedia?.find((media) => media.Url === product.DefaultImageUrl) || null;
    }
);

export const getMainMedia = createSelector(
    getItem,
    getProduct,
    getMedia,
    (productState = {}, product = {}, currentMedia = []) => {
        if (productState.selectedMedia && currentMedia.find((m) => m.Url === productState.selectedMedia.Url)) {
            return productState.selectedMedia;
        }
        if (currentMedia.length) {
            return (
                currentMedia.find((media) => media.MediaGroup === 'Primary') ||
                currentMedia.find((media) => media.IsDefault) ||
                currentMedia.find((media) => media.Url === product.DefaultImageUrl) ||
                currentMedia[0]
            );
        }

        return product.GalleryMedia?.find((media) => media.Url === product.DefaultImageUrl) || null;
    }
);

export const getCurrentPriceInfo = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return {
                price: selected.Price,
                useQtySalePrice:
                    selected.Price.QtySalePrices != null && Object.keys(selected.Price.QtySalePrices).length > 0,
            };
        }
        if (attributes.length && children.length === 1) {
            return {
                price: children[0].Price,
                useQtySalePrice:
                    children[0].Price.QtySalePrices != null && Object.keys(children[0].Price.QtySalePrices).length > 0,
            };
        }
        return { price: product.Price, useQtySalePrice: false };
    }
);

export const getChildByCode = createSelector(
    (_, code) => code,
    getChildren,
    (code, children) => children.find((c) => c.Code === code)
);

export const getChildTitle = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return selected.DisplayName;
        }
        if (attributes.length && children.length && children.length === 1) {
            return children[0].DisplayName;
        }
        return product.DisplayName;
    }
);

export const getSingleActiveChild = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    (selected = null, children = [], attributes = []) => {
        if (selected) {
            return selected;
        }
        if (attributes.length && children.length && children.length === 1) {
            return children[0];
        }
        return null;
    }
);

export const getCurrentItemStatus = createSelector(
    getProduct,
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    (product = {}, selected = null, children = [], attributes = []) => {
        if (selected) {
            return {
                hasStock: selected.StockStatus === 'InStock',
                statusDisplayName: selected.StockStatusLabel,
                code: selected.Code,
            };
        }
        if (attributes.length && children.length === 1) {
            return {
                hasStock: children[0].StockStatus === 'InStock',
                statusDisplayName: children[0].StockStatusLabel,
                code: children[0].Code,
            };
        }

        return {
            hasStock: product.TypeId !== 'Product' && product.StockStatus === 'InStock',
            statusDisplayName: product.StockStatusLabel,
            code: product.Code,
        };
    }
);

export const getCurrentHazmat = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return { code: selected.HazmatCode, description: selected.HazmatDescription };
        }
        if (attributes.length && children.length === 1) {
            return { code: children[0].HazmatCode, description: children[0].HazmatDescription };
        }

        return { code: product.HazmatCode, description: product.HazmatDescription };
    }
);

export const getCurrentMarketingBadges = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return [...new Set(selected.MarketingBadges?.map((badge) => badge.toUpperCase()))];
        }

        if (attributes.length && children.length === 1) {
            return [...new Set(children[0].MarketingBadges?.map((badge) => badge.toUpperCase()))];
        }

        return [...new Set(product.MarketingBadges?.map((badge) => badge.toUpperCase()))];
    }
);

export const getCurrentOversize = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getProduct,
    (selected = null, children = [], attributes = [], product = {}) => {
        if (selected) {
            return { class: selected.OversizeClass, description: selected.OversizeDescription };
        }
        if (attributes.length && children.length === 1) {
            return { code: children[0].OversizeClass, description: children[0].OversizeDescription };
        }

        return { code: product.OversizeClass, description: product.OversizeDescription };
    }
);

export const getProductDefinitions = createSelector(getChildren, (children = []) =>
    children.reduce(
        (acc, child) =>
            acc.concat(
                child.Attributes.filter(
                    (a) => a.IsSpecification && !acc.includes(a.AttributeDefinition) && a.AttributeDefinition !== ''
                ).map((a) => a.AttributeDefinition)
            ),
        []
    )
);

export const getCurrentFeatures = createSelector(
    getSelectedChild,
    getCurrentChildren,
    getSelectedAttributes,
    getFeatures,
    (selected = null, children = [], attributes = [], features = []) => {
        if (selected && selected.Features) {
            return features.concat(selected.Features);
        }
        if (attributes.length && children.length === 1 && children[0].Features) {
            return features.concat(children[0].Features);
        }

        return features;
    }
);

export const getSpecificationsTableDataMobile = createSelector(
    getProduct,
    getChildren,
    getNewBadgeStr,
    (product = {}, children = [], newStr) => {
        let columns = [{ name: product.TypeId, hasStock: true }];
        let rows = [];

        columns = columns.concat(
            children.reduce(
                (colData, child) =>
                    colData.concat([
                        {
                            name: child.Code,
                            hasStock: child.StockStatus === 'InStock',
                            sortOrder: child.SortOrder,
                            badges: newStr && (child.IsNew || child.MarketingBadges?.includes(newStr)) ? [newStr] : [],
                            gtaPayload: gtmUtil.mapEntityToAddCart(child, 1, child.Attributes, GTM.TAGS.PDP),
                        },
                    ]),
                []
            )
        );

        for (let i = 0, l1 = children.length; i < l1; i++) {
            const currentChild = children[i];

            for (let j = 0, l2 = currentChild.Attributes.length; j < l2; j++) {
                const currentAttrubute = currentChild.Attributes[j];

                if (!currentAttrubute.IsSpecification) continue;

                const attributeRowIndex = rows.map((row) => row.label).indexOf(currentAttrubute.AttributeDisplayName);

                if (attributeRowIndex < 0) {
                    //we are adding a new attribute to its own row.
                    const rowValues = [];

                    for (let k = 0; k < children.length; k++) {
                        rowValues[k] = { id: `${children[k].Code}_${currentAttrubute.AttributeName}`, values: [''] }; //add empty values for each child
                    }
                    rowValues[i] = { id: currentAttrubute.AttributeName, values: currentAttrubute.Values }; //update the current child with its attribute values
                    rows = rows.concat([
                        {
                            values: rowValues,
                            label: currentAttrubute.AttributeDisplayName,
                        },
                    ]);
                } else {
                    rows[attributeRowIndex].values[i].values = currentAttrubute.Values; //update an existing row's cell values
                }
            }
        }

        return {
            columns: columns.sort((a, b) => a.sortOrder - b.sortOrder),
            rows,
        };
    }
);

export const getSpecificationsTableDataDesktop = createSelector(
    getProduct,
    getChildren,
    getNewBadgeStr,
    (product = {}, children = [], newStr) => {
        let columns = [product.TypeId];

        columns = columns.concat(
            children.reduce(
                (attributes, child) =>
                    attributes.concat(
                        child.Attributes.reduce(
                            (specifications, attribute) =>
                                attribute.IsSpecification && !attributes.includes(attribute.AttributeDisplayName)
                                    ? [...specifications, attribute.AttributeDisplayName]
                                    : specifications,
                            []
                        )
                    ),
                []
            )
        );

        const rows = children.reduce(
            (rowData, child) =>
                rowData.concat([
                    {
                        label: child.Code,
                        badges: newStr && (child.IsNew || child.MarketingBadges?.includes(newStr)) ? [newStr] : [],
                        values: columns.slice(1).reduce((data, col) => {
                            const columnData = child.Attributes.find((att) => att.AttributeDisplayName === col);

                            return columnData
                                ? [
                                      ...data,
                                      { id: `${child.Code}_${columnData.AttributeName}`, values: columnData.Values },
                                  ]
                                : [
                                      ...data,
                                      {
                                          id: `${child.Code}_${col}`,
                                          values: [' '],
                                      },
                                  ];
                        }, []),
                        hasStock: child.StockStatus === 'InStock',
                        sortOrder: child.SortOrder,
                        gtaPayload: gtmUtil.mapEntityToAddCart(child, 1, child.Attributes, GTM.TAGS.PDP),
                    },
                ]),
            []
        );

        // add gtm data.
        return {
            columns: columns,
            rows: rows.sort((a, b) => a.sortOrder - b.sortOrder),
        };
    }
);

export const getChildFacetData = createSelector(
    getProduct,
    getFacets,
    getNewBadgeStr,
    (product = {}, facets = [], newStr) =>
        product.Children?.map((child) => ({
            badges: newStr && (child.IsNew || child.MarketingBadges?.includes(newStr)) ? [newStr] : [],
            swatch: facets?.find((f) => f.FacetColors?.length)?.FacetColors?.length
                ? child.Attributes?.find(
                      (a) => a.AttributeName === facets?.find((f) => f.FacetColors?.length)?.Attribute
                  )?.Values[0]
                : null,
            id: child.Code,
            imageUrl: child.DefaultImageUrl,
            priceInfo: {
                price: child.Price,
                useQtySalePrice: child.Price.QtySalePrices != null && Object.keys(child.Price.QtySalePrices).length > 0,
            },
            hasStock: child.StockStatus === 'InStock',
            gtaPayload: {
                event: 'addToCart',
                ecommerce: {
                    currencyCode: child.Price.SalePrice.Currency,
                    add: {
                        products: [
                            {
                                name: child.DisplayName,
                                id: child.Code,
                                price: child.Price.SalePrice.Amount,
                                currencyCode: child.Price.SalePrice.Currency,
                                brand:
                                    product.Attributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0] ??
                                    null,
                                category:
                                    product.Attributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0] ??
                                    null,
                                family:
                                    product.Attributes.find((a) => a.AttributeName === 'ProductFamily')?.Values[0] ??
                                    null,
                                series:
                                    product.Attributes.find((a) => a.AttributeName === 'ProductSeries')?.Values[0] ??
                                    null,
                                quantity: 1,
                            },
                        ],
                    },
                },
            },
        }))
);

export const getPackageBundleTableData = createSelector(getProduct, getNewBadgeStr, (product = {}, newStr) =>
    product.Children?.map((child) => ({
        badges: newStr && (child.IsNew || child.MarketingBadges?.includes(newStr)) ? [newStr] : [],
        childRelationQuantity: child.ChildRelationQuantity,
        id: child.Code,
        imageUrl: child.DefaultImageUrl,
        childLink: child.ContentUrl,
        displayName: child.DisplayName,
        priceInfo: {
            price: child.Price,
            useQtySalePrice: child.Price.QtySalePrices != null && Object.keys(child.Price.QtySalePrices).length > 0,
        },
        hasStock: child.StockStatus === 'InStock',
        gtaPayload: {
            event: 'addToCart',
            ecommerce: {
                currencyCode: child.Price.SalePrice.Currency,
                add: {
                    products: [
                        {
                            name: child.DisplayName,
                            id: child.Code,
                            price: child.Price.SalePrice.Amount,
                            currencyCode: child.Price.SalePrice.Currency,
                            brand:
                                product.Attributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0] ?? null,
                            category:
                                product.Attributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0] ??
                                null,
                            family:
                                product.Attributes.find((a) => a.AttributeName === 'ProductFamily')?.Values[0] ?? null,
                            series:
                                product.Attributes.find((a) => a.AttributeName === 'ProductSeries')?.Values[0] ?? null,
                            quantity: 1,
                        },
                    ],
                },
            },
        },
    }))
);

export const getProductTabContent = createSelector(
    getCurrentFeatures,
    getSpecificationsTableDataDesktop,
    getSpecificationsTableDataMobile,
    getChildFacetData,
    getPackageBundleTableData,
    getProduct,
    (
        features = [],
        specificationsDesktop = {},
        specificationsMobile = {},
        childFacetData = {},
        packageBundleData = {},
        product = {}
    ) => {
        let content = [];

        if (product.Description && product.DisplayName) {
            content = content.concat([
                {
                    tab: 'Description',
                    content: {
                        description: product.Description,
                        displayName: product.DisplayName,
                        descriptionBackgroundUrl: product.Options.DescriptionBackground,
                        descriptionImageUrl: product.DescriptionImageUrl,
                    },
                },
            ]);
        }

        if (features && features.length) {
            content = content.concat([{ tab: 'Features', content: features }]);
        }

        switch (product.TypeId) {
            case 'Product':
                if (
                    specificationsDesktop.rows?.length &&
                    specificationsDesktop.columns?.length &&
                    specificationsMobile.rows?.length &&
                    specificationsMobile.columns?.length &&
                    product.Options.ShowSpecifications === 'True'
                ) {
                    content = content.concat([
                        {
                            tab: 'Specifications',
                            content: {
                                tableDataDesktop: specificationsDesktop,
                                tableDataMobile: specificationsMobile,
                                showAddToCartButton: productIsForSale(product),
                            },
                        },
                    ]);
                }
                if (childFacetData.length && product.Options.ShowBulkBuy === 'True') {
                    content = content.concat([{ tab: 'BuyInBulk', content: childFacetData }]);
                }
                break;
            case 'Package':
                if (packageBundleData.length) {
                    content = [
                        {
                            tab: 'Includes',
                            content: packageBundleData,
                            typeId: product.TypeId,
                            showAddToCart: productIsForSale(product),
                        },
                    ].concat(content);
                }
                break;
            case 'Bundle':
                if (packageBundleData.length) {
                    content = [
                        {
                            tab: 'Includes',
                            content: packageBundleData,
                            typeId: product.TypeId,
                            showAddToCart: productIsForSale(product),
                        },
                    ].concat(content);
                }
                break;
        }

        return content;
    }
);
