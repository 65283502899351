import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import { useTranslation, useViewport } from 'Common/hooks';

export default function BackgroundVideo({
    className,
    hideOnMobile,
    id: idProp = `bgvideo-${v4()}`,
    mobileSrc,
    mobileVideoSize,
    src,
    videoAutoPlay,
    videoSize,
    ...props
}) {
    /**
     * @type React.MutableRefObject<HTMLVideoElement>
     */
    const videoRef = React.useRef();
    const id = React.useRef(idProp);
    const viewport = useViewport();
    const isMobile = React.useMemo(() => viewport.is.lt('md'), [viewport]);
    const [suspended, setSuspended] = React.useState(true);
    const [muted, setMuted] = React.useState(true);
    const [playing, setPlaying] = React.useState(false);
    const [key, setKey] = React.useState(v4());
    const playLbl = useTranslation('BackgroundVideo.Play.Label');
    const toggleLbl = useTranslation('BackgroundVideo.Volume.Toggle.Label');

    const videoClass = React.useMemo(
        () =>
            `BackgroundVideo__video ${suspended ? 'BackgroundVideo__video--suspended' : ''} ${
                isMobile && mobileVideoSize ? mobileVideoSize : videoSize
            }`,
        [isMobile, mobileVideoSize, suspended, videoSize]
    );

    const playButtonIconClass = React.useMemo(
        () => (playing ? 'fa fa-fw fa-pause-circle' : 'fa fa-fw fa-play-circle'),
        [playing]
    );

    const volumeButtonIconClass = React.useMemo(
        () => (muted ? 'fa fa-fw fa-volume-mute' : 'fa fa-fw fa-volume-off'),
        [muted]
    );

    const volumeButtonClass = React.useMemo(
        () =>
            muted
                ? 'BackgroundVideo__toggleVolume BackgroundVideo__toggleVolume--muted'
                : 'BackgroundVideo__toggleVolume BackgroundVideo__toggleVolume--unmuted',
        [muted]
    );

    // Video Auto Play can be a string or a boolean since it is coming from a Razor template or a React component.
    // This variable converts everything to a boolean.
    const autoPlay = React.useMemo(() => videoAutoPlay.toString().toLowerCase() === 'true', [videoAutoPlay]);

    const toggleVolume = React.useCallback(() => {
        setMuted((m) => !m);
    }, []);

    // This event can only be called from a click event handler,
    // otherwise the `play` call will fail on iPhone in low
    // power mode.
    const play = React.useCallback(async () => {
        const videoEl = videoRef.current;

        if (!videoEl) return;

        if (videoEl.paused) {
            await videoEl.play();
        } else {
            videoEl.pause();
        }
    }, []);

    React.useEffect(() => {
        const videoEl = videoRef.current;

        if (!videoEl) return;

        function playVideo() {
            setPlaying(true);
        }

        function pauseVideo() {
            setPlaying(false);
        }

        async function init() {
            try {
                // Fails on Safari in low power mode.
                if (autoPlay) await videoEl.play();
            } catch (e) {
                setSuspended(true);
            }
        }

        // Attempt to play the video in order to detect if
        // the video is being force-suspended by the iPhone's
        // low power mode.
        init();

        videoEl.addEventListener('play', playVideo);
        videoEl.addEventListener('pause', pauseVideo);

        return () => {
            videoEl.removeEventListener('play', playVideo);
            videoEl.removeEventListener('pause', pauseVideo);
        };
    }, [autoPlay, src, videoAutoPlay]);

    React.useEffect(() => {
        const videoEl = videoRef.current;

        if (!videoEl) return;

        // Make sure we enable the video controls if we're
        // in low power mode. This allows pausing the video
        // and scrubbing it.
        videoEl.setAttribute('controls', suspended);
    }, [suspended]);

    // Forces re-render when viewport size crosses the mobile breakpoint threshold.
    React.useEffect(() => {
        setKey(v4());
    }, [isMobile]);

    return (
        <div
            className={cx(
                'BackgroundVideo',
                hideOnMobile === 'True' && 'BackgroundVideo--hideOnMobile',
                suspended && 'BackgroundVideo--lowPower',
                className
            )}
            id={id}
            {...props}
            key={key}
        >
            <video
                id={`${id.current}_video`}
                className={videoClass}
                ref={videoRef}
                preload="true"
                autoPlay={autoPlay}
                muted={muted}
                loop
                playsInline
            >
                <source src={isMobile && mobileSrc ? mobileSrc : src} />
            </video>

            {suspended && !playing ? (
                <button onClick={play} className="BackgroundVideo__play" type="button" aria-label={playLbl}>
                    <i className={playButtonIconClass} />
                </button>
            ) : null}

            <div className="BackgroundVideo__actions">
                <button className={volumeButtonClass} onClick={toggleVolume} type="button" aria-label={toggleLbl}>
                    <i className={volumeButtonIconClass} />
                </button>
            </div>
        </div>
    );
}

BackgroundVideo.propTypes = {
    children: px.node,
    className: px.string,
    hideOnMobile: px.bool,
    id: px.string,
    mobileSrc: px.string,
    mobileVideoSize: px.string,
    src: px.string.isRequired,
    style: px.objectOf(px.any),
    videoAutoPlay: px.bool,
    videoSize: px.string,
};
