import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { CatalogQuickView as QuickView } from 'Common/components/catalog';
import { customer, product } from '~features';
import { ProductView, PartialDetailsLinks } from '~components/product';
import * as GTM from 'Common/constants/gtm';

export default function ProStaffQuickView({ className, checkoutPageLink, itemStatusTooltip }) {
    const dispatch = useDispatch();
    const isUserAuthenticated = useSelector(customer.selectors.isLoggedIn);
    const onClearProduct = React.useCallback(() => dispatch(product.actions.clearItemDetails()), [dispatch]);
    const currentProduct = useSelector(product.selectors.getProduct);
    const link = React.useMemo(() => (currentProduct ? currentProduct.ContentUrl : null), [currentProduct]);

    const media = useSelector(product.selectors.getMedia);
    const mainMedia = useSelector(product.selectors.getMainMedia);

    const closeQuick = () => {
        onClearProduct();
        document.getElementById('gear').scrollIntoView();
    };

    return currentProduct ? (
        <div className={cx('ProStaffQuickView', className)}>
            <QuickView
                showRelatedProducts={false}
                Product={ProductView}
                onClose={closeQuick}
                productData={currentProduct}
                checkoutPageLink={checkoutPageLink}
                isUserAuthenticated={isUserAuthenticated}
                productProps={{
                    galleryProps: {
                        media: media,
                        mainMedia: mainMedia,
                        useCarousel: false,
                        desktopBreakpoint: 'lg',
                        allowImageZoom: false,
                    },
                    detailsProps: {
                        Links: <PartialDetailsLinks productLink={link} />,
                        desktopBreakpoint: 'lg',
                        checkoutPageLink: checkoutPageLink,
                        renderedInQuickView: true,
                        useLargeProductTitle: false,
                        gtmListValue: GTM.TAGS.PRO_QUICK,
                    },
                }}
                itemStatusTooltip={itemStatusTooltip}
            />
        </div>
    ) : null;
}

ProStaffQuickView.propTypes = {
    className: px.string,
    checkoutPageLink: px.string,
    itemStatusTooltip: px.string,
};
