import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { Form } from 'informed';
import { useAction, useCatalogEntities } from 'Common/hooks';
import { CartTable } from 'Common/components/cart';
import { Translation } from 'Common/components/localization';
import { PromoCode, GiftCard } from 'Common/components/checkout/payments';
import { Loader } from 'Common/components/ui';
import { CartQuickView } from '~components/cart';
import { order } from '~features';

export default function CartItemList({ accountEmail, allowGiftCards = true }) {
    const loadedRef = React.useRef(false);
    const [giftcardError, setGiftcardError] = React.useState('');
    const hasCart = useSelector(order.selectors.hasCart);
    const loadCart = useAction(order.actions.loadCart);
    const isUpdating = useSelector(order.selectors.isCartUpdating);
    const onChangeQty = useAction(order.actions.updateItemQty);
    const removeItem = useAction(order.actions.removeItem);
    const updateCart = useAction(order.actions.updateCart);
    const onClearChanges = useAction(order.actions.clearChanges);
    const onClearCart = useAction(order.actions.clearCart);
    const orderGiftCards = useSelector(order.selectors.getOrderGiftCards);
    const paymentMethods = useSelector(order.selectors.getPaymentMethods);
    const Forms = useSelector(order.selectors.getCartForms);
    const lineItems = useSelector(order.selectors.getAllLineItems);
    const qtyChanges = useSelector(order.selectors.getQtyChanges);
    const totalQty = useSelector(order.selectors.getTotalQuantity);
    const hasChanges = React.useMemo(() => qtyChanges.length > 0, [qtyChanges]);
    const currency = useSelector(order.selectors.getCurrencyCode);

    const addPromotion = useAction(order.actions.addPromotion);
    const removePromotion = useAction(order.actions.removePromotion);
    const promoError = useSelector(order.selectors.getPromoCodeValidationIssue);

    const promotions = useSelector(order.selectors.getPromoCodes);
    const onAddPayment = useAction(order.actions.addPayment);
    const removePayment = useAction(order.actions.removePayment);
    const giftCardPayment = React.useMemo(
        () => paymentMethods?.find((pm) => pm.SystemName === 'GiftCard'),
        [paymentMethods]
    );

    const catalogEntities = useCatalogEntities(lineItems);

    const savedGiftCards = React.useMemo(() => giftCardPayment?.SavedPaymentInfo, [giftCardPayment]);

    const onCommitChanges = React.useCallback(() => {
        updateCart({ expand: ['forms', 'ordertotals'] });
    }, [updateCart]);

    const onDelete = React.useCallback(
        (args) =>
            removeItem({
                ...args,
                cartApiOptions: {
                    expand: ['forms', 'ordertotals'],
                },
            }),
        [removeItem]
    );

    const submitGiftcard = React.useCallback(
        async (data) => {
            await onAddPayment(
                data,
                undefined,
                (errors, _) => {
                    setGiftcardError(errors.find((e) => e.RefType === 'Payment')?.Issue);
                    setTimeout(() => setGiftcardError(''), 5000);
                },
                { expand: ['ordertotals', 'forms'] },
                false
            );
        },
        [onAddPayment]
    );

    const clearCart = React.useCallback(async () => {
        await onClearCart();
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }, [onClearCart]);

    React.useEffect(() => {
        if (!isUpdating && !loadedRef.current) {
            if (!hasCart || (hasCart && !Forms?.length) || (giftCardPayment && savedGiftCards == null)) {
                loadedRef.current = true;
                loadCart({
                    expand: ['forms', 'ordertotals', 'savedpayments'],
                    update: ['lineitems', 'shipping', 'payment', 'promotions'],
                });
            }
        }
    }, [isUpdating, hasCart, loadCart, Forms, giftCardPayment, savedGiftCards]);

    return (
        <div className="CartItemListing w-100">
            {!Forms?.length || isUpdating ? <Loader /> : null}
            <CartTable
                Forms={Forms}
                onChangeQty={onChangeQty}
                catalogEntities={catalogEntities}
                onDelete={onDelete}
                qtyChanges={qtyChanges}
                currency={currency}
                QuickView={CartQuickView}
            />
            <div className="toolbar">
                {hasChanges ? (
                    <button className="btn btn-small btn-link" type="button" role="button" onClick={onClearChanges}>
                        <span>
                            <i className="fas fa-times" aria-hidden="true" />
                            &nbsp;
                            <Translation id="Commerce.Order.Button.ClearChanges.Label" />
                        </span>
                    </button>
                ) : null}
                <div className="span">&nbsp;</div>
                <button
                    className="btn btn-small"
                    type="button"
                    role="button"
                    onClick={onCommitChanges}
                    disabled={!hasChanges}
                >
                    <span>
                        <Translation id="Commerce.Order.Button.UpdateCart.Label" />
                    </span>
                </button>
                <button className="btn btn-small" type="button" role="button" onClick={clearCart} disabled={!totalQty}>
                    <span>
                        <Translation id="Commerce.Order.Button.RemoveItems.Label" />
                    </span>
                </button>
            </div>
            <div className="container p-0">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <PromoCode
                            promotions={promotions}
                            addPromotion={addPromotion}
                            removePromotion={removePromotion}
                            promoError={promoError}
                        />
                    </div>
                    {allowGiftCards ? (
                        <Form className="col-12 col-lg-6">
                            <GiftCard
                                onUpdate={submitGiftcard}
                                accountEmail={accountEmail}
                                giftCards={orderGiftCards}
                                removePayment={removePayment}
                                savedGiftCards={savedGiftCards}
                                errorMessage={giftcardError}
                            />
                        </Form>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

CartItemList.propTypes = {
    accountEmail: px.string,
    allowGiftCards: px.bool,
};
