import * as React from 'react';
import Slider from 'react-slick';
import $ from 'jquery';
import cx from 'classnames';
import px from 'prop-types';
import * as PRODUCT from '~config/product';
import { useViewport, useTranslator } from 'Common/hooks';
import { ArrowButton, Carousel, CarouselItem, ImageModal, MediaConverter, PagedCarousel } from 'Common/components/ui';

const slickSliderSettings = ({ slidesToShow = [4, 4, 3, 2, 1], slidesToScroll = 1, mobileDots = true } = {}) => {
    const [xl, lg = xl, md = lg, sm = md, xs = sm] = slidesToShow;

    return {
        slidesToShow: xl,
        slidesToScroll,
        dots: false,
        focusOnSelect: true,
        infinite: false,
        prevArrow: <ArrowButton direction="left" ariaHidden />,
        nextArrow: <ArrowButton direction="right" ariaHidden />,
        responsive: [
            {
                breakpoint: 1199,
                settings: { slidesToShow: lg },
            },
            {
                breakpoint: 991,
                settings: { slidesToShow: md },
            },
            {
                breakpoint: 767,
                settings: {
                    focusOnSelect: !mobileDots,
                    slidesToShow: sm,
                    dots: mobileDots,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    focusOnSelect: !mobileDots,
                    slidesToShow: xs,
                    dots: mobileDots,
                },
            },
        ],
    };
};

export default function ProductGallery({
    allowImageZoom = true,
    className,
    media = [],
    mainMedia,
    onSelectImage,
    desktopBreakpoint = 'md',
    useCarousel = false,
    useCarouselMobile = false,
    useModalCarousel = true,
    slidesToShow = [4, 4, 3, 2, 1], // slick only
    slidesToScroll = 1, // slick only
    showGalleryMobile = false,
    onMainImageClick,
    children,
    largeCarouselImages = false,
    noGallery = false,
}) {
    const viewport = useViewport();
    const isMobile = React.useMemo(() => viewport.is.lt(desktopBreakpoint), [viewport, desktopBreakpoint]);
    const thumbTranslator = useTranslator('Commerce.Product.Gallery.SelectThumb.Label');
    const [modalContent, setModalContent] = React.useState();
    const [showModalCarousel, setShowModalCarousel] = React.useState();
    const [showModal, setShowModal] = React.useState(false);
    const items = React.useMemo(
        () => media.filter((m) => m?.Url && ((m.MediaType === 'video' && m.ThumbnailUrl) || m.MediaType === 'image')),
        [media]
    );

    const primaryItem = React.useMemo(() => mainMedia ?? items[0], [mainMedia, items]);

    const slickSettings = React.useMemo(
        () =>
            slickSliderSettings({
                slidesToScroll,
                slidesToShow: Array.isArray(slidesToShow) ? slidesToShow : Array(5).fill(slidesToShow),
                mobileDots: !showGalleryMobile,
            }),
        [slidesToScroll, slidesToShow, showGalleryMobile]
    );

    const onOpenModal = React.useCallback(
        (selectedMedia) => () => {
            if (allowImageZoom && selectedMedia?.MediaType === 'image') {
                setModalContent({
                    type: selectedMedia.FileType,
                    src: selectedMedia.Url,
                    isMobile: isMobile,
                    selectedItem: selectedMedia,
                });
                setShowModal(true);
            }
        },
        [allowImageZoom]
    );

    const onClickGalleryItem = React.useCallback(
        (selectedMedia) =>
            !showGalleryMobile && (isMobile || useCarousel === 'react-paged')
                ? null
                : () => onSelectImage(selectedMedia),
        [onSelectImage, isMobile, showGalleryMobile, useCarousel]
    );

    const onClickGalleryImg = React.useCallback(
        (selectedMedia) =>
            (isMobile || useCarousel === 'react-paged') && !showGalleryMobile ? onOpenModal(selectedMedia) : null,
        [isMobile, useCarousel, onOpenModal, showGalleryMobile]
    );

    const closeModal = React.useCallback(() => setShowModal(false), []);

    const onSelectItem = React.useCallback((m) => onClickGalleryItem(m)(), [onClickGalleryItem]);

    const setShowModalSlider = React.useCallback(() => setShowModalCarousel(true), []);

    React.useEffect(() => {
        $('div.slick-slide[aria-hidden="true"]').attr('aria-hidden', false);
    }, []);

    const imageResizeSearchQuery = React.useMemo(
        () =>
            `format=png&${
                !largeCarouselImages && (showGalleryMobile || !isMobile) ? 'width=90&height=70' : 'height=220&width=440'
            }${PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''}`,
        [isMobile, showGalleryMobile, largeCarouselImages]
    );

    const imageResizeSearchQueryNoWidth = React.useMemo(
        () =>
            `format=png&${!largeCarouselImages && (showGalleryMobile || !isMobile) ? 'height=70' : 'height=220'}${
                PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
            }`,
        [isMobile, showGalleryMobile, largeCarouselImages]
    );

    const noGalleryMobile = React.useMemo(() => !useCarousel || showGalleryMobile || !isMobile, [
        useCarousel,
        showGalleryMobile,
        isMobile,
    ]);

    const carousel = React.useMemo(() => {
        if (!isMobile) {
            return useCarousel;
        } else if (useCarouselMobile) {
            return useCarouselMobile;
        } else {
            return useCarousel;
        }
    }, [isMobile, useCarousel, useCarouselMobile]);

    return (
        <div className={cx('ProductGallery', className)}>
            {!noGallery && noGalleryMobile ? (
                <MediaConverter
                    media={primaryItem}
                    className="fit-image-main w-100"
                    imageResizeSearchQuery={`format=png&height=450&width=550${
                        PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                    }`}
                    autoPlayVideo={true}
                    renderSpinAsThumb={!carousel}
                    onSelectPhoto={onMainImageClick ?? onOpenModal(primaryItem)}
                    onSelectSpin={onMainImageClick ?? onOpenModal(primaryItem)}
                />
            ) : null}
            {carousel === true || carousel === 'slick' ? (
                <Slider {...slickSettings}>
                    {media.map((m) =>
                        m?.Url && ((m.MediaType === 'video' && m.ThumbnailUrl) || m.MediaType === 'image') ? (
                            <div key={m.Url}>
                                <button
                                    onClick={onClickGalleryItem(m)}
                                    className={cx('btn', m?.Url === mainMedia?.Url && 'selected')}
                                    aria-label={thumbTranslator(m)}
                                >
                                    <MediaConverter
                                        media={m}
                                        onSelectPhoto={onClickGalleryImg(m)}
                                        onSelectSpin={onClickGalleryImg(m)}
                                        className="fit-image-nav img-fluid"
                                        imageResizeSearchQuery={imageResizeSearchQuery}
                                        renderVideoAsThumb={!isMobile || showGalleryMobile}
                                        renderSpinAsThumb
                                        autoPlayVideo
                                    />
                                </button>
                            </div>
                        ) : null
                    )}
                </Slider>
            ) : carousel === 'react' ? (
                <Carousel
                    items={items}
                    onSelectItem={onSelectItem}
                    itemProps={{ onClickGalleryImg, showGalleryMobile, isMobile, imageResizeSearchQuery }}
                    keyBy="Url"
                    Item={CarouselItem}
                    scrollToSelected
                    animateScroll
                />
            ) : carousel === 'react-paged' ? (
                <PagedCarousel gap={15} onSelectItem={onSelectItem} maxVisibleItems={3}>
                    {items.map((item, i) => (
                        <CarouselItem
                            onClickGalleryImg={onClickGalleryImg}
                            showGalleryMobile={showGalleryMobile}
                            isMobile={isMobile}
                            imageResizeSearchQuery={imageResizeSearchQueryNoWidth}
                            item={item}
                            key={i}
                        />
                    ))}
                </PagedCarousel>
            ) : null}
            <ImageModal
                className="ProductGallery__Modal"
                closeModal={closeModal}
                isMobile={isMobile}
                items={items}
                modalContent={modalContent}
                modalSize="xl"
                setModalContent={setModalContent}
                setShowModalSlider={setShowModalSlider}
                showModal={showModal}
                showCarousel={useModalCarousel && showModalCarousel}
            />
            {children}
        </div>
    );
}

ProductGallery.propTypes = {
    allowImageZoom: px.bool,
    className: px.string,
    media: px.arrayOf(
        px.shape({
            MediaType: px.string,
            MediaGroup: px.string,
            Url: px.string,
            Thumbnail: px.string,
            FileType: px.string,
            Order: px.number,
            IsDefault: px.bool,
        })
    ),
    mainMedia: px.shape({
        MediaType: px.string,
        MediaGroup: px.string,
        Url: px.string,
        Thumbnail: px.string,
        FileType: px.string,
        Order: px.number,
        IsDefault: px.bool,
    }),
    children: px.node,
    desktopBreakpoint: px.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    childDetails: px.arrayOf(px.shape({ status: px.string, code: px.string })),
    onSelectImage: px.func,
    useCarousel: px.oneOf([true, false, 'react', 'slick', 'react-paged']),
    useCarouselMobile: px.oneOf([true, false, 'react', 'slick', 'react-paged']),
    useModalCarousel: px.bool,
    slidesToScroll: px.number,
    slidesToShow: px.oneOfType([px.number, px.arrayOf(px.number)]),
    showGalleryMobile: px.bool,
    onMainImageClick: px.func,
    largeCarouselImages: px.bool,
    noGallery: px.bool,
    pageSize: px.number,
    visibleOverflow: px.bool,
    useFullWidthCarousel: px.bool,
};
