import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { CatalogQuickView as QuickView } from 'Common/components/catalog';
import * as types from 'Common/types';
import { useAction } from 'Common/hooks';
import * as GTM from 'Common/constants/gtm';
import { product } from '~features';
import { ProductView, PartialDetailsLinks } from '~components/product';

export default function CatalogQuickView(props) {
    const { checkoutPageLink, gtmListValue } = props;
    const lastSelected = React.useRef(null);
    const setItemDetails = useAction(product.actions.setItemDetails);
    const setSelectedCode = useAction(product.actions.setSelectedCode);
    const clearItemDetails = useAction(product.actions.clearItemDetails);
    const fetchProductDetails = useAction(product.actions.fetchProductDetails);
    const onQuantityChange = useAction(product.actions.selectQuantity);
    const selectedProduct = useSelector(product.selectors.getSelectedProduct);
    const currentProduct = useSelector(product.selectors.getProduct);
    const selectedCode = useSelector(product.selectors.getSelectedCode);
    const media = useSelector(product.selectors.getMedia);
    const mainMedia = useSelector(product.selectors.getMainMedia);
    const link = React.useMemo(() => (currentProduct ? currentProduct.ContentUrl : null), [currentProduct]);

    const onClearProduct = React.useCallback(() => {
        setSelectedCode('');
        clearItemDetails();
    }, [setSelectedCode, clearItemDetails]);

    const onSetProduct = React.useCallback(
        async (productData) => {
            if (productData.Children) {
                await setItemDetails({ Product: { ...productData } });
            } else {
                await setSelectedCode(productData.Code);
            }
            onQuantityChange(1);
        },
        [setItemDetails, setSelectedCode, onQuantityChange]
    );

    React.useEffect(() => {
        if (selectedCode) {
            lastSelected.current = selectedCode;
            if (currentProduct?.Code !== selectedCode) {
                if (!selectedProduct) fetchProductDetails(selectedCode);
                else setItemDetails({ Product: JSON.parse(JSON.stringify(selectedProduct)) });
            }
        } else if (lastSelected.current) {
            lastSelected.current = null;
            clearItemDetails();
        }
    }, [selectedCode, setItemDetails, fetchProductDetails, clearItemDetails, selectedProduct, currentProduct]);

    return (
        <QuickView
            {...props}
            showRelatedProducts={false}
            Product={ProductView}
            onSetProduct={onSetProduct}
            loading={!selectedCode || selectedCode !== currentProduct?.Code}
            onClearProduct={onClearProduct}
            productProps={{
                galleryProps: {
                    media: media,
                    mainMedia: mainMedia,
                    useCarousel: false,
                    desktopBreakpoint: 'lg',
                },
                detailsProps: {
                    Links: <PartialDetailsLinks productLink={link} />,
                    desktopBreakpoint: 'lg',
                    checkoutPageLink: checkoutPageLink,
                    renderedInQuickView: true,
                    useLargeProductTitle: false,
                    gtmListValue: gtmListValue ?? GTM.TAGS.PLP,
                },
            }}
        />
    );
}

CatalogQuickView.propTypes = {
    checkoutPageLink: px.string,
    productData: types.Product,
    gtmListValue: px.string,
};
