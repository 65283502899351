import * as React from 'react';
import $ from 'jquery';
import cx from 'classnames';
import px from 'prop-types';
import { Product } from 'Common/types';
import { ProductBuyInBulk, ProductFeatures, ProductSpecifications, ProductIncludes } from './TabContent';
import { Translation } from 'Common/components/localization';
import { Icon } from 'Common/components/ui';
import ContentSwitch from './ContentSwitch';

const TAB_NAME_LOCALIZATION = {
    Includes: 'Commerce.Product.Tabs.Includes.TabName',
    Description: 'Commerce.Product.Tabs.Description.TabName',
    Features: 'Commerce.Product.Tabs.Features.TabName',
    Specifications: 'Commerce.Product.Tabs.Specifications.TabName',
    BuyInBulk: 'Commerce.Product.Tabs.BuyInBulk.TabName',
};

export default function ProductAccordion({
    className,
    content,
    displayOnly,
    gtmListValue,
    isUserAuthenticated,
    onAddItemToCart,
    orderLastUpdate,
    pageId,
    productDefinitions,
    productInfo,
    specsTableBreakpoint,
    statusTooltip,
    DescriptionTab,
    BuyInBulkTab = ProductBuyInBulk,
    FeaturesTab = ProductFeatures,
    SpecificationsTab = ProductSpecifications,
    IncludesTab = ProductIncludes,
    hideImage = false,
    hideTitle = false,
    multiExpansion = false,
    startExpandedIndex = -1,
    useChevron = false,
}) {
    const [expanded, setExpanded] = React.useState(
        content.reduce(
            (obj, tab, index) => ({
                ...obj,
                [tab.tab]: startExpandedIndex === index,
            }),
            {}
        )
    );

    const onToggle = React.useCallback(
        (key) =>
            setExpanded(
                multiExpansion
                    ? {
                          ...expanded,
                          [key]: !expanded[key],
                      }
                    : content.reduce(
                          (obj, tab) => ({ ...obj, [tab.tab]: tab.tab === key ? !expanded[key] : false }),
                          {}
                      )
            ),
        [expanded, content, multiExpansion]
    );

    React.useLayoutEffect(() => {
        Object.keys(expanded).forEach((key) => {
            $(`#${pageId}_cat_${key} > .content`).css(
                'max-height',
                expanded[key] ? Math.ceil($(`#${pageId}_cat_${key} > .content > :first-child`).outerHeight(true)) : 0
            );
        });
    }, [expanded, pageId]);

    return (
        <div className={cx('ProductAccordion', className)}>
            <ul role="tablist">
                {content.map((tab) => (
                    <li
                        className={cx('category', tab.tab, { active: expanded[tab.tab] })}
                        id={`${pageId}_cat_${tab.tab}`}
                        key={`${pageId}_${tab.tab}`}
                    >
                        <a
                            role="button"
                            aria-expanded={expanded[tab.tab]}
                            name={`${pageId}_${tab.tab}`}
                            onClick={() => onToggle(tab.tab)}
                        >
                            <Translation id={TAB_NAME_LOCALIZATION[tab.tab]} />
                            {useChevron ? (
                                <Icon icon={expanded[tab.tab] ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
                            ) : (
                                <Icon name={expanded[tab.tab] ? 'minus' : 'plus'} />
                            )}
                        </a>
                        <div className="content">
                            <ContentSwitch
                                BuyInBulkTab={BuyInBulkTab}
                                DescriptionTab={DescriptionTab}
                                displayOnly={displayOnly}
                                FeaturesTab={FeaturesTab}
                                forceMobile
                                gtmListValue={gtmListValue}
                                hideImage={hideImage} // description tab prop
                                hideTitle={hideTitle} // description tab prop
                                IncludesTab={IncludesTab}
                                isUserAuthenticated={isUserAuthenticated}
                                itemStatusTooltip={statusTooltip}
                                onAddItemToCart={onAddItemToCart}
                                orderLastUpdate={orderLastUpdate}
                                productDefinitions={productDefinitions}
                                productInfo={productInfo}
                                SpecificationsTab={SpecificationsTab}
                                specsTableBreakpoint={specsTableBreakpoint}
                                tab={tab}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

ProductAccordion.propTypes = {
    BuyInBulkTab: px.elementType,
    className: px.string,
    content: px.arrayOf(
        px.shape({
            tab: px.string,
            content: px.any,
            typeId: px.string,
            showAddToCart: px.bool,
        })
    ),
    DescriptionTab: px.elementType,
    displayOnly: px.bool,
    FeaturesTab: px.elementType,
    gtmListValue: px.string,
    hideImage: px.bool,
    hideTitle: px.bool,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    IncludesTab: px.elementType,
    multiExpansion: px.bool,
    onAddItemToCart: px.func,
    orderLastUpdate: px.number,
    pageId: px.string,
    productDefinitions: px.arrayOf(px.string),
    productInfo: Product,
    SpecificationsTab: px.elementType,
    specsTableBreakpoint: px.number,
    startExpandedIndex: px.number,
    statusTooltip: px.oneOf([false, true, undefined, null, 'top', 'bottom']),
    useChevron: px.bool,
};
