import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { ProductListView } from 'Common/components/product';
import { Loader } from '../ui';

export default function Quickview({
    checkoutPageLink,
    className,
    googleRecaptchaV3Sitekey,
    gtmListValue,
    inputStatusTooltip,
    isMobile = false,
    isUserAuthenticated,
    loading = false,
    noBuyNow,
    NotifyMe,
    notifyProps,
    onAddItemToCart,
    onAddMultipleToCart,
    onClearProduct,
    onClose,
    onSetProduct,
    product,
    useRecaptcha,
    variants,
}) {
    React.useEffect(() => {
        if (onSetProduct) onSetProduct(product);
        return () => {
            if (onClearProduct) onClearProduct();
        };
    }, [product, onClearProduct, onSetProduct]);

    return (
        <>
            {loading ? (
                <Loader fitToContainer />
            ) : (
                <ProductListView
                    checkoutPageLink={checkoutPageLink}
                    className={cx('Quickview', className)}
                    googleRecaptchaV3Sitekey={googleRecaptchaV3Sitekey}
                    gtmListValue={gtmListValue}
                    inputStatusTooltip={inputStatusTooltip}
                    isUserAuthenticated={isUserAuthenticated}
                    noBuyNow={noBuyNow}
                    NotifyMe={NotifyMe}
                    notifyProps={notifyProps}
                    onAddItemToCart={onAddItemToCart}
                    onAddMultipleToCart={onAddMultipleToCart}
                    onClose={onClose}
                    overrideIsMobile={isMobile}
                    productInfo={product}
                    useRecaptcha={useRecaptcha}
                    variants={variants}
                />
            )}
        </>
    );
}

Quickview.propTypes = {
    checkoutPageLink: px.string,
    className: px.string,
    googleRecaptchaV3Sitekey: px.string,
    gtmListValue: px.string,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    isMobile: px.bool,
    isUserAuthenticated: px.bool,
    loading: px.bool,
    noBuyNow: px.bool,
    NotifyMe: px.elementType,
    notifyProps: px.objectOf(px.any),
    onAddItemToCart: px.func,
    onAddMultipleToCart: px.func,
    onClearProduct: px.func,
    onClose: px.func,
    onSetProduct: px.func,
    product: types.Product,
    useRecaptcha: px.bool,
    variants: types.Product,
};
