import * as React from 'react';
import { useSelector } from 'react-redux';
import { CatalogAltQuickview as Quickview } from 'Common/components/catalog';
import { useAction } from 'Common/hooks';
import { product } from '~features';

export default function CatalogAltQuickview(props) {
    const lastSelected = React.useRef(null);
    const clearItemDetails = useAction(product.actions.clearItemDetails);
    const fetchProductDetails = useAction(product.actions.fetchProductDetails);
    const onQuantityChange = useAction(product.actions.selectQuantity);
    const setItemDetails = useAction(product.actions.setItemDetails);
    const setSelectedCode = useAction(product.actions.setSelectedCode);
    const currentProduct = useSelector(product.selectors.getProduct);
    const selectedCode = useSelector(product.selectors.getSelectedCode);
    const selectedProduct = useSelector(product.selectors.getSelectedProduct);

    const onClearProduct = React.useCallback(() => {
        setSelectedCode('');
        clearItemDetails();
    }, [setSelectedCode, clearItemDetails]);

    const onSetProduct = React.useCallback(
        async (productData) => {
            if (productData.Children) {
                await setItemDetails({ Product: { ...productData } });
            } else {
                await setSelectedCode(productData.Code);
            }
            onQuantityChange(1);
        },
        [setItemDetails, setSelectedCode, onQuantityChange]
    );

    React.useEffect(() => {
        if (selectedCode) {
            lastSelected.current = selectedCode;
            if (currentProduct?.Code !== selectedCode) {
                if (!selectedProduct) fetchProductDetails(selectedCode);
                else setItemDetails({ Product: JSON.parse(JSON.stringify(selectedProduct)) });
            }
        } else if (lastSelected.current) {
            lastSelected.current = null;
            clearItemDetails();
        }
    }, [selectedCode, setItemDetails, fetchProductDetails, clearItemDetails, selectedProduct, currentProduct]);

    return (
        <Quickview
            loading={!selectedCode || selectedCode !== currentProduct?.Code}
            onClearProduct={onClearProduct}
            onSetProduct={onSetProduct}
            product={product}
            variants={currentProduct?.Children}
            {...props}
        />
    );
}
