import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { Url } from 'Common/utils';
import { useAction, useCatalogEntities, useLocationHash } from 'Common/hooks';
import { InlineCart as Cart } from 'Common/components/cart';
import { order } from '~features';
import * as ORDER from '~config/order';
import { TAGS } from 'Common/constants/gtm';

export default function InlineCart(props) {
    const loadCount = React.useRef(0);
    const [loading, setLoading] = React.useState(false);

    const startLoader = React.useCallback(() => {
        loadCount.current += 1;
        setLoading(true);
    }, []);

    const stopLoader = React.useCallback(() => {
        loadCount.current -= 1;
        if (!loadCount.current) setLoading(false);
    }, []);

    const loadedRef = React.useRef(false);
    const loadCart = useAction(order.actions.loadCart);
    const isUpdating = useSelector(order.selectors.isCartUpdating);
    const hasCart = useSelector(order.selectors.hasCart);
    const forms = useSelector(order.selectors.getCartForms);
    const currency = useSelector(order.selectors.getCurrencyCode);
    const { subtotal } = useSelector(order.selectors.getCartSummaryData);
    const qtyChanges = useSelector(order.selectors.getQtyChanges);
    const cartId = useSelector(order.selectors.getCartId);
    const lineItems = useSelector(order.selectors.getAllLineItems);
    const onChangeQty = useAction(order.actions.updateItemQty);
    const onAddItemToCart = useAction(order.actions.addItem, startLoader, stopLoader);
    const onDelete = useAction(order.actions.removeItem, startLoader, stopLoader);
    const updateCart = useAction(order.actions.updateCart, startLoader, stopLoader);
    const lastQtyChange = React.useRef({ hash: JSON.stringify(qtyChanges.sort()), count: qtyChanges.length });
    const catalogEntities = useCatalogEntities(lineItems);

    const debounceUpdate = React.useMemo(
        () => {
            let expantions = ['forms'];

            //ensure correct order data is displayed without page reload at checkout
            expantions = location.href.includes(props.checkoutPageLink) ? [...expantions, 'ordertotals'] : expantions; 

            return debounce(() => {
                if (lastQtyChange.current.count) return updateCart({ expand: expantions});
            }, ORDER.AUTO_UPDATE_QTY_DEBOUNCE_DELAY_MS)
        },
        [updateCart, props.checkoutPageLink]
    );

    const hashes = useLocationHash({ asCommaSeparatedArray: true });

    const isOpen = React.useMemo(() => hashes.includes(ORDER.CART_LOCATION_HASH), [hashes]);

    const onClose = React.useCallback(() => {
        Url.current.delHash(ORDER.CART_LOCATION_HASH).apply();
    }, []);

    React.useEffect(() => {
        const qtyHash = JSON.stringify(qtyChanges.sort());

        if (lastQtyChange.current.hash !== qtyHash) {
            lastQtyChange.current.hash = qtyHash;
            lastQtyChange.current.count = qtyChanges.length;
            debounceUpdate();
        }
    }, [qtyChanges, debounceUpdate]);

    React.useEffect(() => {
        if (isOpen && !isUpdating && !forms?.length && !loadedRef.current) {
            loadedRef.current = true;
            loadCart({ expand: ['forms'], update: ['lineitems', 'promotions'] });
        }
    }, [loadCart, isOpen, hasCart, isUpdating, forms]);

    return (
        <Cart
            loading={!forms?.length || loading}
            loaded={!!forms?.length}
            catalogEntities={catalogEntities}
            open={isOpen}
            onClose={onClose}
            forms={forms}
            subtotal={subtotal}
            onAddItemToCart={onAddItemToCart}
            onDelete={onDelete}
            qtyChanges={qtyChanges}
            onChangeQty={onChangeQty}
            currency={currency}
            gtmListValue={TAGS.CART}
            cartId={cartId}
            isEditable
            {...props}
        />
    );
}

InlineCart.propTypes = {
    className: px.string,
    checkoutPageLink: px.string,
};
