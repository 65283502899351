import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { BREAKPOINTS } from 'Common/utils/Viewport';
import * as types from 'Common/types';
import { useBEM, useViewport } from 'Common/hooks';
import { InfiniteScroll } from 'Common/components/ui';
import { CatalogAltQuickview } from 'Common/components/catalog';
import GridCard from './GridCard';

export const DEFAULT_CARD_SIZE = 600;

export function maxRowSize(bp, colSize = DEFAULT_CARD_SIZE) {
    const min = BREAKPOINTS[bp] || 0;

    return Math.max(1, Math.floor(min / colSize));
}

export function calculateQuickviewIndex(quickview, rowSize) {
    return quickview ? Math.ceil((quickview.idx + 1) / rowSize) * rowSize - 1 : -1;
}

export default function CatalogSimpleGrid({
    checkoutPageLink,
    className,
    colSize = DEFAULT_CARD_SIZE,
    facets = [],
    googleRecaptchaV3Sitekey,
    gtmListValue,
    inputStatusTooltip,
    isUserAuthenticated,
    noBuyNow,
    NotifyMe,
    notifyProps,
    onAddItemToCart,
    onAddMultipleToCart,
    onLoadMore,
    pageInfo = {},
    products = [],
    Quickview = CatalogAltQuickview,
    stacked = false,
    useRecaptcha,
}) {
    const viewPort = useViewport();
    const handler = React.useRef();
    const [hide, setHide] = React.useState(false);
    const [quickview, setQuickview] = React.useState(null);
    const isDoneLoading = React.useMemo(() => pageInfo.page * pageInfo.pageSize >= pageInfo.total, [pageInfo]);
    const [b, em] = useBEM('CatalogSimpleGrid', className);

    const rowSize = React.useMemo(
        () => ((stacked || products.length <= 2) && viewPort.is.gt('lg') ? 2 : maxRowSize(viewPort.name, colSize)),
        [viewPort.name, viewPort.is, stacked, colSize, products]
    );

    const quickviewIdx = React.useMemo(
        () => Math.min(calculateQuickviewIndex(quickview, rowSize), products.length - 1),
        [rowSize, quickview, products]
    );

    const closeQuickview = React.useCallback(() => {
        setHide(true);
        clearTimeout(handler.current);
        handler.current = setTimeout(() => {
            setQuickview(null);
        }, 310);
    }, []);

    React.useEffect(() => {
        if (facets.some((f) => f.Values.some((v) => v.Selected))) {
            closeQuickview();
        }
    }, [facets, closeQuickview]);

    const onOpenQuickview = React.useCallback(
        (idx, product) => (e) => {
            e.stopPropagation();
            e.preventDefault();
            setHide(false);
            clearTimeout(handler.current);
            setQuickview({ idx, product });
        },
        []
    );

    const onCloseQuickview = React.useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            closeQuickview();
        },
        [closeQuickview]
    );

    React.useEffect(() => () => clearTimeout(handler.current), []);

    return (
        <div
            className={cx(b, { [em(null, 'stacked')]: stacked })}
            style={{ '--grid-col-size': stacked ? '50%' : `${colSize}px` }}
        >
            <InfiniteScroll onLoad={onLoadMore} useLoadIndicator={true} isDoneLoading={isDoneLoading}>
                <div className={em('grid')} data-row-size={rowSize}>
                    {products.map((x, i) => (
                        <React.Fragment key={`ceg-${x.Code}`}>
                            <GridCard
                                className={cx(em('grid__card'), { [em('grid__card', 'rod')]: stacked })}
                                active={quickview?.idx === i}
                                onOpenQuickview={onOpenQuickview(i, x)}
                                onCloseQuickview={onCloseQuickview}
                                product={x}
                                stacked={stacked || rowSize === 1}
                                imgWidth={stacked ? 0 : 430}
                                imgHeight={stacked ? 224 : 0}
                            />
                            {quickviewIdx === i ? (
                                <Quickview
                                    className={cx('CatalogListCard', em('grid__quickview'), {
                                        [em('grid__quickview', 'hide')]: hide,
                                    })}
                                    product={quickview.product}
                                    isMobile={rowSize === 1}
                                    NotifyMe={NotifyMe}
                                    notifyProps={notifyProps}
                                    onClose={closeQuickview}
                                    gtmListValue={gtmListValue}
                                    isUserAuthenticated={isUserAuthenticated}
                                    checkoutPageLink={checkoutPageLink}
                                    useRecaptcha={useRecaptcha}
                                    googleRecaptchaV3Sitekey={googleRecaptchaV3Sitekey}
                                    onAddItemToCart={onAddItemToCart}
                                    onAddMultipleToCart={onAddMultipleToCart}
                                    inputStatusTooltip={inputStatusTooltip}
                                    noBuyNow={noBuyNow}
                                />
                            ) : null}
                        </React.Fragment>
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    );
}

CatalogSimpleGrid.propTypes = {
    checkoutPageLink: px.string,
    className: px.string,
    colSize: px.number,
    facets: px.arrayOf(types.Facet),
    googleRecaptchaV3Sitekey: px.string,
    gtmListValue: px.string,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    isUserAuthenticated: px.bool,
    noBuyNow: px.bool,
    NotifyMe: px.elementType,
    notifyProps: px.object,
    onAddItemToCart: px.func,
    onAddMultipleToCart: px.func,
    onLoadMore: px.func,
    pageInfo: types.PageInfo,
    products: px.arrayOf(types.Product),
    Quickview: px.elementType,
    stacked: px.bool,
    useRecaptcha: px.bool,
};
