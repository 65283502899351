import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import * as types from 'Common/types';
import { Translation } from 'Common/components/localization';
import CartItem from './CartItem';
import { useViewport } from 'Common/hooks';

export default function CartItemList({
    className,
    id: idProp,
    items = [],
    shipmentId = 0,
    qtyChanges = [],
    onChangeQty,
    onDeleteItem,
    onEditItem,
    inputStatusTooltip,
    onBackToCustomer,
    onBackToShipping,
    recapData = null,
    recapDisabled = false,
    currency,
    title,
    faux = false,
    disabled = false,
    clickable = false,
}) {
    const id = React.useRef(idProp || `cart-item-list-${v4()}`);
    const viewport = useViewport();
    const onEdit = React.useCallback(
        (lineItem) => (onEditItem ? (productInfo) => onEditItem(lineItem, productInfo) : null),
        [onEditItem]
    );

    const onDelete = React.useCallback(
        (lineItem) => (onDeleteItem ? () => onDeleteItem({ shipmentId, itemCode: lineItem.Code, faux }) : null),
        [onDeleteItem, shipmentId, faux]
    );

    const onQtyChange = React.useCallback(
        (lineItem) => (onChangeQty ? (num) => onChangeQty(shipmentId, lineItem.Code, num) : null),
        [onChangeQty, shipmentId]
    );

    const qty = React.useMemo(
        () =>
            items.reduce(
                (obj, item) => ({
                    ...obj,
                    [item.Code]:
                        qtyChanges.find((c) => c.itemCode === item.Code && c.shipmentId === shipmentId)?.qty ??
                        item.Quantity,
                }),
                {}
            ),
        [items, qtyChanges, shipmentId]
    );

    const recapWrapper = React.useCallback(
        (fn) => (e) => {
            if (viewport.is.gt('xs') || e.target?.classList?.contains('edit-action')) {
                fn();
            }
        },
        [viewport]
    );

    return (
        <div className={cx('CartItemList', className)} id={id.current}>
            {title ? <div className="CartItemList__title">{title}</div> : null}

            {recapData && onBackToCustomer ? (
                <>
                    <button
                        type="button"
                        role="button"
                        className="recap"
                        onClick={recapWrapper(onBackToCustomer)}
                        disabled={recapDisabled}
                    >
                        <div>
                            <div>
                                <strong>
                                    <Translation
                                        id={
                                            recapData.digital
                                                ? 'Commerce.Order.Checkout.Recap.DigitalAddress.Label'
                                                : 'Commerce.Order.Checkout.Recap.ShippingAddress.Label'
                                        }
                                    />
                                </strong>
                            </div>
                            <div>
                                <span className="recap-value">{recapData.address}</span>
                            </div>
                        </div>
                        <div>
                            <span className="color-primary">
                                <Translation id="Commerce.Order.Checkout.Recap.ChangeButton.Label" />
                            </span>
                        </div>
                    </button>
                    {!recapData.digital && recapData.method && onBackToShipping ? (
                        <button
                            type="button"
                            role="button"
                            className="recap"
                            onClick={recapWrapper(onBackToShipping)}
                            disabled={recapDisabled}
                        >
                            <div>
                                <div>
                                    <strong>
                                        <Translation id="Commerce.Order.Checkout.Recap.ShippingMethod.Label" />
                                    </strong>
                                </div>
                                <div className="recap-value">{recapData.method}</div>
                            </div>
                            <div>
                                <span className="color-primary">
                                    <Translation id="Commerce.Order.Checkout.Recap.ChangeButton.Label" />
                                </span>
                            </div>
                        </button>
                    ) : null}
                    {recapData.gift && onBackToShipping ? (
                        <button
                            type="button"
                            role="button"
                            className="recap"
                            onClick={recapWrapper(onBackToShipping)}
                            disabled={recapDisabled}
                        >
                            <div>
                                <div>
                                    <strong>
                                        <Translation id="Commerce.Order.Checkout.GiftMessage.Display" />
                                    </strong>
                                </div>
                                <div>{recapData.gift}</div>
                            </div>
                            <div>
                                <span className="color-primary">
                                    <Translation id="Commerce.Order.Checkout.Recap.ChangeButton.Label" />
                                </span>
                            </div>
                        </button>
                    ) : null}
                </>
            ) : null}

            {items.map((item) => (
                <CartItem
                    key={`cart_items_${id.current}_${item.LineItemId}`}
                    qty={qty[item.Code]}
                    onChangeQty={onQtyChange(item)}
                    item={item}
                    onEdit={onEdit(item)}
                    onDelete={onDelete(item)}
                    disabled={disabled}
                    clickable={clickable}
                    currency={currency}
                    allowZeroQty={faux}
                    inputStatusTooltip={inputStatusTooltip}
                />
            ))}
        </div>
    );
}

CartItemList.propTypes = {
    recapData: px.shape({
        digital: px.bool,
        address: px.string,
        method: px.string,
        gift: px.string,
    }),
    onBackToCustomer: px.func,
    onBackToShipping: px.func,
    recapDisabled: px.bool,
    clickable: px.bool,
    className: px.string,
    id: px.string,
    items: px.arrayOf(types.CartLineItem),
    onChangeQty: px.func,
    onDeleteItem: px.func,
    qtyChanges: px.arrayOf(px.any),
    onEditItem: px.func,
    disabled: px.bool,
    title: px.node,
    faux: px.bool,
    currency: px.string,
    shipmentId: px.oneOfType([px.string, px.number]),
    inputStatusTooltip: px.oneOfType([px.bool, px.string]),
};
