import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import * as PRODUCT from '~config/product';
import { ProductListTable, ProductInput, ProductDetailCard } from 'Common/components/product';
import { useViewport } from 'Common/hooks';
import { ProductImage } from 'Common/utils';
import { Translation } from 'Common/components/localization';

function getImageStructureClass(idx, structKey) {
    const imgCol = 'col-md-3';

    switch (structKey) {
        case 'top':
            return null;
        case 'right':
            return `${imgCol} order-md-2`;
        case 'alt':
            return idx % 2 == 0 ? `${imgCol} order-md-1` : `${imgCol} order-md-2`;
        default:
            return imgCol;
    }
}

function getDetailsStructureClass(idx, structKey) {
    const detailsCol = 'col-md-9';

    switch (structKey) {
        case 'top':
            return null;
        case 'right':
            return `${detailsCol} order-md-1`;
        case 'alt':
            return idx % 2 == 0 ? `${detailsCol} order-md-2` : `${detailsCol} order-md-1`;
        default:
            return detailsCol;
    }
}

export default function CatalogListCard({
    checkoutPageLink,
    className,
    cardIdx,
    gtmListValue,
    inputStatusTooltip,
    imgPosition,
    isUserAuthenticated,
    NotifyMe,
    notifyProps,
    onAddItemToCart,
    onAddMultipleToCart,
    product,
}) {
    const viewport = useViewport();
    const [qtyMap, setQtyMap] = React.useState({});
    const image = React.useMemo(
        () =>
            ProductImage.getGlamOrDefaultImageUrl(
                product.CatalogMedia,
                product.DefaultImageUrl,
                `format=png&width=800${PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''}`
            ),
        [product]
    );

    const itemCodes = React.useMemo(() => product?.Children?.map((c) => c.Code), [product]);

    const props = React.useMemo(
        () => ({ checkoutPageLink, isUserAuthenticated, gtmListValue }),
        [checkoutPageLink, isUserAuthenticated, gtmListValue]
    );

    const totalQty = React.useMemo(() => Object.values(qtyMap).reduce((sum, x) => sum + x, 0), [qtyMap]);

    const stockStatus = React.useMemo(
        () => ({
            code: product.Code,
            statusDisplayName: product.StockStatusLabel,
            hasStock: product.StockStatus === 'InStock',
        }),
        [product]
    );

    const qtyChanger = React.useCallback((code) => (qty) => onQtyChange(qty, code), [onQtyChange]);

    const onQtyChange = React.useCallback(
        (qty, code) => {
            setQtyMap({ ...qtyMap, [code]: qty });
        },
        [qtyMap]
    );

    const onTableAddToCart = React.useCallback(
        (_1, _2, opts) => onAddMultipleToCart(qtyMap, opts),
        [onAddMultipleToCart, qtyMap]
    );

    const goToProduct = React.useCallback(() => {
        window.location.href = product.ContentUrl;
    }, [product]);

    return (
        <div className={cx('CatalogListCard row', className)}>
            <div
                className={cx(
                    'CatalogListCard__Image col-12 p-0 pt-3',
                    imgPosition,
                    getImageStructureClass(cardIdx, imgPosition)
                )}
            >
                <a role="link" href={product.ContentUrl}>
                    <img alt={product.ProductName ?? product.DisplayName} src={image} />
                </a>
            </div>
            <div className={cx('CatalogListCard__Details col-12 p-0', getDetailsStructureClass(cardIdx, imgPosition))}>
                <a
                    role="link"
                    aria-label={product.ProductName ?? product.DisplayName}
                    href={product.ContentUrl}
                    className="CatalogListCard__Details__Title"
                >
                    {product.ProductName ?? product.DisplayName}
                </a>
                {product?.Children?.length && product.Facets?.length ? (
                    viewport.is.mobile ? (
                        <>
                            <a role="link" className="product-link" href={product.ContentUrl}>
                                <Translation id="Commerce.Catalog.Product.Link" />
                            </a>
                            <div className="CatalogListCard__Details__CatalogListDetailsCards">
                                <div className="CatalogListCard__Details__CatalogListDetailsCards__Container">
                                    {product.Children.map((c) => (
                                        <ProductDetailCard
                                            key={`list__${c.Code}`}
                                            onAddToCart={onAddItemToCart}
                                            onQuantityChange={qtyChanger(c.Code)}
                                            quantity={qtyMap[c.Code]}
                                            variant={c}
                                            facets={product.Facets}
                                            NotifyMe={NotifyMe}
                                            notifyProps={notifyProps}
                                            expandable={false}
                                            showTitle={false}
                                            inputStatusTooltip={inputStatusTooltip}
                                            collapseDisplayOnlyFacets
                                            showItemData
                                        />
                                    ))}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <ProductListTable
                                variants={product.Children}
                                facets={product.Facets}
                                onQuantityChange={onQtyChange}
                                quantities={qtyMap}
                                onRowClick={goToProduct}
                                NotifyMe={NotifyMe}
                                notifyProps={notifyProps}
                            />
                            <ProductInput
                                className="CatalogListCard__Details__Input"
                                quantity={totalQty}
                                onAddToCart={onTableAddToCart}
                                currentItemStatus={stockStatus}
                                productInfo={product}
                                currentChildren={[product]}
                                statusTooltip={inputStatusTooltip}
                                itemCodes={itemCodes}
                                hideWhenOOS
                                {...props}
                            />
                        </>
                    )
                ) : null}
            </div>
        </div>
    );
}

CatalogListCard.propTypes = {
    checkoutPageLink: px.string,
    className: px.string,
    cardIdx: px.number,
    gtmListValue: px.string,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    imgPosition: px.string,
    isUserAuthenticated: px.bool,
    NotifyMe: px.elementType,
    notifyProps: px.objectOf(px.any),
    onAddItemToCart: px.func,
    onAddMultipleToCart: px.func,
    product: types.Product,
};
