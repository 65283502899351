import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { CatalogList as List } from 'Common/components/catalog';
import { catalog, order } from '~features';
import { NotificationSubscription } from 'Common/containers/customer';

export default function CatalogList({ inputStatusTooltip, ...props }) {
    const items = useSelector(catalog.selectors.getFilteredProducts);
    const pageInfo = useSelector(catalog.selectors.getPageInfo);
    const onLoadMore = useAction(catalog.actions.loadMore);
    const onAddItemToCart = useAction(order.actions.addItem);
    const onAddMultipleToCart = useAction(order.actions.addItems);

    return (
        <List
            {...props}
            NotifyMe={NotificationSubscription}
            onAddItemToCart={onAddItemToCart}
            onAddMultipleToCart={onAddMultipleToCart}
            pageInfo={pageInfo}
            products={items}
            onLoadMore={onLoadMore}
            inputStatusTooltip={inputStatusTooltip}
        />
    );
}

CatalogList.propTypes = {
    gtmListValue: px.string,
    viewDetails: px.string,
    inputStatusTooltip: px.string,
};
