import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useTranslation } from 'Common/hooks';
import { ProductView } from '../product';
import { Loader } from '../ui';
import FakeQuickViewItem from './FakeQuickViewItem';

export default function CatalogQuickView({
    className,
    isUserAuthenticated,
    showRelatedProducts = false,
    Product = ProductView,
    productProps = {},
    onSetProduct,
    onClearProduct,
    loading = false,
    productData,
    variantData,
    children,
    onClose,
    checkoutPageLink,
    itemStatusTooltip,
}) {
    const quickviewRef = React.useRef(null);
    const lblClose = useTranslation('Commerce.CatalogNode.QuickView.Close.Label');
    const scrollToRef = React.useCallback((ref) => {
        return setTimeout(() => {
            ref?.current?.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }, 300);
    }, []);

    React.useEffect(() => {
        const handler = scrollToRef(quickviewRef);

        if (onSetProduct) onSetProduct(productData, variantData);
        return () => {
            if (onClearProduct) onClearProduct();
            clearTimeout(handler);
        };
    }, [productData, onClearProduct, variantData, scrollToRef, onSetProduct]);

    return (
        <span className={cx('CatalogQuickView', className)} ref={quickviewRef}>
            <button className="btn btn-close btn-lg btn-link" title={lblClose} aria-label={lblClose} onClick={onClose}>
                <i className="fas fa-times-circle" />
            </button>
            {loading ? (
                <>
                    <Loader fitToContainer />
                    <FakeQuickViewItem />
                </>
            ) : (
                <>
                    <Product
                        {...productProps}
                        showRelatedProducts={showRelatedProducts}
                        isUserAuthenticated={isUserAuthenticated}
                        checkoutPageLink={checkoutPageLink}
                        itemStatusTooltip={itemStatusTooltip}
                    />
                    {children}
                </>
            )}
        </span>
    );
}

CatalogQuickView.propTypes = {
    className: px.string,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    showRelatedProducts: px.bool,
    Product: px.elementType,
    productProps: px.objectOf(px.any),
    onSetProduct: px.func,
    onClearProduct: px.func,
    productData: types.Product,
    onClose: px.func,
    children: px.node,
    checkoutPageLink: px.string,
    loading: px.bool,
    itemStatusTooltip: px.bool,
    variantData: types.Product,
};
