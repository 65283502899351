import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import $ from 'jquery';
import { Product, Facet } from 'Common/types';
import { useProductDetails, useTranslation } from 'Common/hooks';
import { Icon } from 'Common/components/ui';
import { Translation } from 'Common/components/localization';
import ProductInput from './ProductInput';
import ProductBadge from './ProductBadge';
import ProductPrice from './ProductPrice';
import { ProductVariant } from 'Common/models';

export default function ProductDetailCard({
    checkoutPageLink,
    className,
    collapseDisplayOnlyFacets = false,
    expandable = false,
    facets,
    gtmListValue,
    hideInput = false,
    inputStatusTooltip,
    isUserAuthenticated,
    noBuyNow = false,
    NotifyMe,
    notifyProps,
    onAddToCart,
    onClickView,
    onQuantityChange,
    quantity = 1,
    showItemData = false,
    showTitle = true,
    showVariantImages = false,
    startExpanded = false,
    variant,
}) {
    const id = React.useRef(`DetailsCard${v4()}`);
    const toggleLbl = useTranslation('Commerce.Product.DetailsCard.Toggle.Label');
    const { badges, attributes, stockStatus, priceInfo } = useProductDetails(
        variant,
        { Facets: facets },
        { useFacets: true, includeDisplayOnlyFacets: true }
    );
    const isDisplayOnly = ProductVariant.isDisplayOnly(variant);

    const canHideAttr = React.useMemo(() => !!attributes.filter((a) => a.isDisplayOnly)?.length, [attributes]);
    const filteredAttr = React.useMemo(() => attributes.filter((a) => !collapseDisplayOnlyFacets || !a.isDisplayOnly), [
        attributes,
        collapseDisplayOnlyFacets,
    ]);

    const displayOnlyRef = React.useRef(null);
    const nameEl = React.useRef();
    const [showDisplayOnly, setShowDisplayOnly] = React.useState();
    const [expanded, setExpanded] = React.useState(!expandable || startExpanded);
    const toggleExpanded = React.useCallback(() => {
        if (expandable) {
            setExpanded(!expanded);
            $(`#${id.current}_Details`).collapse('toggle');
        }
    }, [expanded, expandable]);

    const toggleDisplayOnly = React.useCallback(() => setShowDisplayOnly(!showDisplayOnly), [showDisplayOnly]);

    React.useEffect(() => {
        if (!expandable) setExpanded(true);
    }, [expandable]);

    React.useEffect(() => {
        if (displayOnlyRef.current) {
            const height = showDisplayOnly ? displayOnlyRef.current.children[0]?.getBoundingClientRect()?.height : 0;

            displayOnlyRef.current.style.height = `${height}px`;
        }
    }, [showDisplayOnly]);
    return (
        <div className={cx('ProductDetailCard', className, { expanded })}>
            {showTitle ? (
                <div className="ProductDetailCard__name d-flex" ref={nameEl}>
                    <h6>{variant.DisplayName}</h6>
                    {expanded && showVariantImages ? (
                        <button
                            className="btn btn-primary btn-sm gallery-btn"
                            onClick={() => onClickView(variant.GalleryMedia)}
                            disabled={!variant.GalleryMedia.length || variant.GalleryMedia[0].ContentId == 0}
                        >
                            <i className="far fa-images" />
                        </button>
                    ) : null}
                    {expandable ? (
                        <button
                            data-toggle="collapse"
                            data-target={`#${id.current}_Details`}
                            className="ProductDetailCard__btn"
                            onClick={toggleExpanded}
                            aria-label={toggleLbl}
                        >
                            <Icon name={expanded ? 'minus' : 'plus'} />
                        </button>
                    ) : null}
                </div>
            ) : null}
            <div className={cx('collapse', startExpanded || !expandable ? 'show' : null)} id={`${id.current}_Details`}>
                <div className="ProductDetailCard__container">
                    <ProductBadge badges={badges} />
                    <table width="100%">
                        <tbody>
                            {showItemData ? (
                                <tr>
                                    <th>
                                        <span>
                                            <Translation id="Commerce.Product.Tabs.TypeId.Label" />
                                        </span>
                                    </th>
                                    <td>
                                        <span>{variant.Code}</span>
                                    </td>
                                </tr>
                            ) : null}
                            {filteredAttr.map(({ name, value }) => (
                                <tr key={`detail-card__${variant.Code}__${name}`}>
                                    <th>
                                        <span>{name}</span>
                                    </th>
                                    <td>
                                        <span>{value}</span>
                                    </td>
                                </tr>
                            ))}
                            {collapseDisplayOnlyFacets ? (
                                <tr>
                                    <td className="p-0" colSpan={2}>
                                        <div className="ProductDetailCard__container__displayonly" ref={displayOnlyRef}>
                                            <div>
                                                {attributes
                                                    .filter((a) => a.isDisplayOnly)
                                                    .map(({ name, value }) => (
                                                        <div
                                                            className="ProductDetailCard__container__displayonly__row"
                                                            key={`detail-card__${variant.Code}__${name}`}
                                                        >
                                                            <div className="ProductDetailCard__container__displayonly__row__col">
                                                                <span>{name}</span>
                                                            </div>
                                                            <div className="ProductDetailCard__container__displayonly__row__cell">
                                                                <span>{value}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : null}
                            {collapseDisplayOnlyFacets && canHideAttr ? (
                                <tr
                                    className="ProductDetailCard__container__displayonly__toggle"
                                    onClick={toggleDisplayOnly}
                                    tabIndex="-1"
                                >
                                    <th>
                                        {showDisplayOnly ? (
                                            <Translation id="Commerce.Product.DetailsCard.ShowLess" />
                                        ) : (
                                            <Translation id="Commerce.Product.DetailsCard.ShowMore" />
                                        )}
                                    </th>
                                    <td>
                                        <Icon icon={`fas fa-chevron-${showDisplayOnly ? 'up' : 'down'}`} />
                                    </td>
                                </tr>
                            ) : null}
                            <tr>
                                <th>
                                    <Translation id="Commerce.Product.Tabs.Price.Label" />
                                </th>
                                <td>
                                    <span>
                                        <ProductPrice priceInfo={priceInfo} quantity={quantity} noRanges />
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {!stockStatus.hasStock && !isDisplayOnly ? <NotifyMe {...notifyProps} code={variant.Code} /> : null}
                    {hideInput ? null : (
                        <ProductInput
                            className="ProductDetailCard__input"
                            quantity={quantity}
                            onQuantityChange={onQuantityChange}
                            onAddToCart={onAddToCart}
                            currentItemStatus={stockStatus}
                            productInfo={variant}
                            currentChildren={[variant]}
                            isUserAuthenticated={isUserAuthenticated}
                            gtmListValue={gtmListValue}
                            checkoutPageLink={checkoutPageLink}
                            noBuyNow={noBuyNow}
                            statusTooltip={inputStatusTooltip}
                            itemCodes={[variant.Code]}
                            numInputButtons
                            noSidePadding
                            hideWhenOOS
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

ProductDetailCard.propTypes = {
    checkoutPageLink: px.string,
    className: px.string,
    collapseDisplayOnlyFacets: px.bool,
    expandable: px.bool,
    facets: px.arrayOf(Facet),
    gtmListValue: px.string,
    hideInput: px.bool,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    isUserAuthenticated: px.bool,
    noBuyNow: px.bool,
    NotifyMe: px.elementType,
    notifyProps: px.objectOf(px.any),
    onAddToCart: px.func,
    onClickView: px.func,
    onQuantityChange: px.func,
    quantity: px.number,
    showItemData: px.bool,
    showTitle: px.bool,
    showVariantImages: px.bool,
    startExpanded: px.bool,
    variant: Product,
};
