/* eslint-disable react/prop-types */
import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Location } from 'Common/utils';
import { useTranslation, useScript } from 'Common/hooks';
import { Spinner } from 'Common/components/ui';

const LocationSearch = React.forwardRef(function LocationSearchComponent(
    {
        className,
        options = {},
        type = 'text',
        onRequestLocation,
        onPlaceChange,
        onClearInput,
        placeholder,
        name,
        apiKey,
        region = 'us',
        value,
        children,
        ...rest
    },
    inputRef
) {
    const autoComplete = React.useRef(null);
    const optionsRef = React.useRef(options);
    const defaultInputRef = React.useRef();
    const inputRefRef = React.useRef(inputRef ?? defaultInputRef);
    const locationTip = useTranslation('Dealer.Locator.Search.CurrentLocation.ToolTip');
    const clearTip = useTranslation('Dealer.Locator.Search.ClearInput.ToolTip');
    const google = useScript(
        apiKey ? `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&region=${region}` : null,
        {
            onLoad: async (lib) => {
                const maps = await lib.maps.importLibrary('maps');

                lib.maps = { ...lib.maps, ...maps };
                return lib;
            },
            globalNamespace: 'google',
            delay: 2000,
            destroyOnDismount: false,
        }
    );

    const onChange = React.useCallback(() => {
        const nextPlace = autoComplete.current.getPlace();

        if (nextPlace?.geometry && onPlaceChange) {
            onPlaceChange(Location.addressFromPlace(nextPlace));
        }
    }, [onPlaceChange]);

    const onChangeRef = React.useRef(onChange);

    React.useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    React.useEffect(() => {});

    React.useEffect(() => {
        function handler(e) {
            return onChangeRef.current(e);
        }

        if (google && !autoComplete.current) {
            const el = inputRefRef.current.current;

            autoComplete.current = new google.maps.places.Autocomplete(el, optionsRef.current);
            autoComplete.current.addListener('place_changed', handler);
            Location.ui.enableEnterKey(el, autoComplete.current);
            return () => google.maps.event.clearInstanceListeners(el);
        }
    }, [google]);

    return google ? (
        <div className={cx('LocationSearch', className)}>
            {onRequestLocation ? (
                <button
                    className="locateBtn searchBtn btn btn-sm"
                    title={locationTip}
                    onClick={onRequestLocation}
                    disabled={!onRequestLocation}
                >
                    <i className="fas fa-crosshairs" aria-hidden="true" />
                </button>
            ) : null}
            <input
                className="storeLocatorSearchInput"
                aria-label={placeholder || name}
                placeholder={placeholder}
                name={name}
                type={type}
                ref={inputRefRef.current}
                value={value}
                {...rest}
            />
            {value && onClearInput ? (
                <button className="clearBtn searchBtn btn btn-sm" title={clearTip} onClick={onClearInput}>
                    <i className="fas fa-times" aria-hidden="true" />
                </button>
            ) : null}
            {children}
        </div>
    ) : (
        <div className={cx('LocationSearch', className)}>
            <Spinner />
        </div>
    );
});

export default LocationSearch;

LocationSearch.propTypes = {
    options: px.objectOf(px.any),
    onPlaceChange: px.func,
    type: px.string,
    placeholder: px.string,
    className: px.string,
    children: px.node,
    onRequestLocation: px.func,
    name: px.string,
    style: px.objectOf(px.any),
};
