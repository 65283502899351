import { setCustomerError } from '../customer/actions';
import * as types from './types';
import * as api from './api';
import state from './state';
import { getDisabledFacetValues } from './sel';

export function setItemDetails(details) {
    return {
        type: types.SET_DETAILS,
        payload: details,
    };
}

export function selectSelectedMedia(selectedMedia) {
    return {
        type: types.SET_SELECTED_MEDIA,
        payload: selectedMedia,
    };
}

export function setSelectedCode(code) {
    return {
        type: types.SET_SELECTED_CODE,
        payload: code,
    };
}

export function selectQuantity(quantity) {
    return {
        type: types.SET_QUANTITY,
        payload: quantity,
    };
}

export function clearItemDetails() {
    return {
        type: types.SET_DETAILS,
        payload: state.details,
    };
}

export function selectFacetValue(facet, value) {
    return {
        type: facet && facet.SelectorControlType.includes('multi') ? types.SELECT_MULTI_FACET : types.SELECT_FACET,
        payload: { facet, value },
    };
}

export function deselectAllFacetValues() {
    return {
        type: types.DESELECT_ALL_FACET_VALUES,
    };
}

export function selectFacet(facet, value) {
    return (dispatch, getState) => {
        const disabledMap = getDisabledFacetValues(getState());

        if (
            disabledMap[facet.Attribute] &&
            (disabledMap[facet.Attribute] === true || disabledMap[facet.Attribute][value.Value]?.isDisabled)
        ) {
            dispatch(clearFacets());
        }

        dispatch(selectFacetValue(facet, value));
    };
}

export function selectChild(child) {
    return {
        type: types.SELECT_CHILD,
        payload: { child },
    };
}

export function setChild(child) {
    return {
        type: types.SET_CHILD,
        payload: { child },
    };
}

export function selectChildAttributes(child) {
    return { type: types.SELECT_CHILD_ATTRIBUTES, payload: { child } };
}

export function clearFacets() {
    return (dispatch) => {
        dispatch(deselectAllFacetValues());
        dispatch(setChild(null));
    };
}

export function trackProductQuickviewClick(code) {
    return async () => {
        const response = await api.trackProductQuickviewClick(code);

        return response;
    };
}

/**
 * @param {string|string[]} codes [single string code or array of codes]
 */
export function fetchProductDetails(codes) {
    return async (dispatch) => {
        const data = await api.fetchProductDetails(codes);

        if (data?.success) {
            dispatch({ type: types.ADD_PRODUCT_ENTRIES, payload: data.products });
        } else {
            const codeArr = typeof codes === 'string' ? [codes] : codes;

            dispatch({
                type: types.ADD_PRODUCT_ENTRIES,
                payload: codeArr.map((code) => ({ Code: code, Children: [] })),
            });
            if (data?.errors && data.errors[0]) dispatch(setCustomerError(data.errors[0]));
            console.warn(`Failed to retrieve product details for codes: ${codeArr.join(', ')}`);
        }
    };
}

export function fetchRelatedProducts(code) {
    return async (dispatch) => {
        const data = await api.getRelatedProducts(code);

        if (data?.success) {
            dispatch({
                type: types.UPDATE_DETAILS,
                payload: {
                    CatalogRelatedProducts: data.CatalogRelatedProducts || [],
                    EpiRecommendationAlternativeProducts: data.EpiRecommendationAlternativeProducts || [],
                    EpiRecommendationCrossSellProducts: data.EpiRecommendationCrossSellProducts || [],
                },
            });
        } else {
            console.warn(`Failed to retrieve related products for ${code}`, data?.errors);
            dispatch({
                type: types.UPDATE_DETAILS,
                payload: {
                    CatalogRelatedProducts: [],
                    EpiRecommendationAlternativeProducts: [],
                    EpiRecommendationCrossSellProducts: [],
                },
            });
        }
    };
}
