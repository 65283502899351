import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { CatalogExploreGrid as Grid } from 'Common/components/catalog';
import { NotificationSubscription } from 'Common/containers/customer';
import { catalog, order } from '~features';
import { useAction } from 'Common/hooks';
import CatalogAltQuickview from './CatalogAltQuickview';

export default function CatalogExploreGrid({ gtmListValue, ...props }) {
    const items = useSelector(catalog.selectors.getFilteredProducts);
    const pageInfo = useSelector(catalog.selectors.getPageInfo);
    const onLoadMore = useAction(catalog.actions.loadMore);
    const onAddItemToCart = useAction(order.actions.addItem);
    const onAddMultipleToCart = useAction(order.actions.addItems);

    return (
        <Grid
            gtmListValue={gtmListValue}
            NotifyMe={NotificationSubscription}
            onAddItemToCart={onAddItemToCart}
            onAddMultipleToCart={onAddMultipleToCart}
            onLoadMore={onLoadMore}
            pageInfo={pageInfo}
            products={items}
            Quickview={CatalogAltQuickview}
            {...props}
        />
    );
}

CatalogExploreGrid.propTypes = {
    gtmListValue: px.string,
    viewDetails: px.string,
};
