import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { CatalogGrid as Grid } from 'Common/components/catalog';
import { catalog, compare, customer } from '~features';
import { CatalogQuickView } from '~components/catalog';
import { useAction } from 'Common/hooks';

export default function CatalogGrid({ ctaModel, gtmListValue, skipChildEnrichment = false, ...props }) {
    const items = useSelector(catalog.selectors.getFilteredProducts);
    const pageInfo = useSelector(catalog.selectors.getPageInfo);
    const isUserAuthenticated = useSelector(customer.selectors.isLoggedIn);
    const lastUpdate = useSelector(catalog.selectors.lastUpdate);
    const loadMore = useAction(catalog.actions.loadMore);
    const onAddItemToCompare = useAction(compare.actions.addToCompareList);

    const onLoadMore = React.useCallback(() => loadMore(skipChildEnrichment), [loadMore, skipChildEnrichment]);

    return (
        <Grid
            {...props}
            isUserAuthenticated={isUserAuthenticated}
            ctaModel={ctaModel}
            QuickView={CatalogQuickView}
            gtmListValue={gtmListValue}
            lastUpdate={lastUpdate}
            pageInfo={pageInfo}
            products={items}
            onLoadMore={onLoadMore}
            onAddItemToCompare={onAddItemToCompare}
        />
    );
}

CatalogGrid.propTypes = {
    ctaModel: px.objectOf(px.any),
    gtmListValue: px.string,
    viewDetails: px.string,
    skipChildEnrichment: px.bool,
    siteId: px.string,
};
