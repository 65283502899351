import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useBEM, useProductDetails } from 'Common/hooks';
import * as PRODUCT from '~config/product';
import { ProductBadge, ProductListView } from 'Common/components/product';
import { ProductImage } from 'Common/utils';

export default function GridCard({
    className,
    product,
    checkoutPageLink,
    gtmListValue,
    notifyProps,
    isUserAuthenticated,
    NotifyMe,
    onAddItemToCart,
    onAddMultipleToCart,
    useRecaptcha,
    googleRecaptchaV3Sitekey,
    noBuyNow,
    inputStatusTooltip,
    singleFacet,
}) {
    const [b, em] = useBEM('GridCard', className);
    const { badges } = useProductDetails(product);
    const [primary] = React.useMemo(
        () => [
            ProductImage.getGlamOrDefaultImageUrl(
                product.CatalogMedia,
                product.DefaultImageUrl,
                `format=png&width=430&height=430${PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''}`
            ),
        ],
        [product]
    );

    return (
        <a className={b} aria-label={product.ProductName} href={product.ContentUrl}>
            <ProductBadge className={em('badges')} badges={badges} />
            <div
                className={cx(em('image'), em('image', 'primary'))}
                style={{ backgroundImage: `url('${primary}')` }}
                aria-hidden="true"
            ></div>
            <div className={em('title')}>
                <h4>{product.DisplayName}</h4>
            </div>
            <ProductListView
                className={em('details')}
                variants={product.Children}
                productInfo={product}
                gtmListValue={gtmListValue}
                NotifyMe={NotifyMe}
                isUserAuthenticated={isUserAuthenticated}
                notifyProps={notifyProps}
                checkoutPageLink={checkoutPageLink}
                useRecaptcha={useRecaptcha}
                googleRecaptchaV3Sitekey={googleRecaptchaV3Sitekey}
                onAddItemToCart={onAddItemToCart}
                onAddMultipleToCart={onAddMultipleToCart}
                inputStatusTooltip={inputStatusTooltip}
                noBuyNow={noBuyNow}
                overrideIsMobile={false}
                singleFacet={singleFacet}
                stopPropagation
                noFacets
                hideTitle
            />
        </a>
    );
}

GridCard.propTypes = {
    className: px.string,
    product: types.Product,

    checkoutPageLink: px.string,
    gtmListValue: px.string,
    isUserAuthenticated: px.bool,
    NotifyMe: px.elementType,
    notifyProps: px.objectOf(px.any),
    onAddItemToCart: px.func,
    onAddMultipleToCart: px.func,
    useRecaptcha: px.bool,
    googleRecaptchaV3Sitekey: px.string,
    noBuyNow: px.bool,
    singleFacet: px.string,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
};
