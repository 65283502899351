import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { ProductDetails as Details } from 'Common/components/product';
import { useAction } from 'Common/hooks';
import { LinkItem } from 'Common/types';
import { DetailsLinks } from '~components/product';
import { compare, product, order } from '~features';

export default function ProductDetails({ Links, storeLocatorLink, reviewProviderEntityId, useCompare, ...props }) {
    const productTitle = useSelector(product.selectors.getChildTitle);
    const priceInfo = useSelector(product.selectors.getCurrentPriceInfo);
    const productInfo = useSelector(product.selectors.getProduct);
    const quantity = useSelector(product.selectors.getQuantity);
    const productChildren = useSelector(product.selectors.getChildren);
    const currentItemStatus = useSelector(product.selectors.getCurrentItemStatus);
    const currentChildren = useSelector(product.selectors.getCurrentChildren);
    const currentOversize = useSelector(product.selectors.getCurrentOversize);
    const selectedChild = useSelector(product.selectors.getSelectedChild);
    const facets = useSelector(product.selectors.getFacets);
    const currentHazmat = useSelector(product.selectors.getCurrentHazmat);
    const disabledFacets = useSelector(product.selectors.getDisabledFacetValues);
    const currentMarketingBadges = useSelector(product.selectors.getCurrentMarketingBadges);
    const onFacetSelect = useAction(product.actions.selectFacet);
    const onSelectChild = useAction(product.actions.selectChild);
    const onQuantityChange = useAction(product.actions.selectQuantity);
    const orderLastUpdate = useSelector(order.selectors.getLastUpdate);
    const onAddItemToCart = useAction(order.actions.addItem);
    const onAddItemToCompare = useAction(compare.actions.addToCompareList);
    const clearFacets = useAction(product.actions.clearFacets);

    return (
        <Details
            {...props}
            clearFacets={clearFacets}
            currentChildren={currentChildren}
            currentHazmat={currentHazmat}
            currentItemStatus={currentItemStatus}
            currentMarketingBadges={currentMarketingBadges}
            currentOversize={currentOversize}
            disabledFacets={disabledFacets}
            facets={facets}
            Links={
                Links || (
                    <DetailsLinks
                        retailSearch={storeLocatorLink}
                        onAddItemToCompare={useCompare ? onAddItemToCompare : null}
                    />
                )
            }
            onAddItemToCart={onAddItemToCart}
            onFacetSelect={onFacetSelect}
            onQuantityChange={onQuantityChange}
            onSelectChild={onSelectChild}
            orderLastUpdate={orderLastUpdate}
            priceInfo={priceInfo}
            productChildren={productChildren}
            productInfo={productInfo}
            productTitle={productTitle}
            quantity={quantity}
            reviewProviderEntityId={reviewProviderEntityId}
            selectedChild={selectedChild}
        />
    );
}

ProductDetails.propTypes = {
    Links: px.node,
    storeLocatorLink: LinkItem,
    reviewProviderEntityId: px.string,
    useCompare: px.bool,
};
