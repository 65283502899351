import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useProductDetails } from 'Common/hooks';
import { Product } from 'Common/types';
import { Translation } from 'Common/components/localization';
import ProductGallery from './ProductGallery';
import ProductPrice from './ProductPrice';
import ProductInput from './ProductInput';
import ProductBadge from './ProductBadge';
import { ProductVariant } from 'Common/models';

export default function ProductGridCell({
    variant,
    onAddToCart,
    className,
    gtmListValue,
    addToCartLabel,
    checkoutPageLink,
    isUserAuthenticated,
    inputReverseButtonOrder,
    inputNumInputButtons,
    inputStatusTooltip,
    resetQtyAfterAdd = false,
    noQty = false,
    NotifyMe,
    notifyProps,
    defaultQuantity = 1,
}) {
    const [qty, setQty] = React.useState(defaultQuantity);
    const { badges, mainMedia, priceInfo, stockStatus } = useProductDetails(variant);
    const isDisplayOnly = ProductVariant.isDisplayOnly(variant);
    const addToCart = React.useCallback(
        async (...args) => {
            let res = undefined;

            if (onAddToCart) {
                res = await onAddToCart(...args);
                if (resetQtyAfterAdd) setQty(1);
            }
            return res;
        },
        [onAddToCart, resetQtyAfterAdd]
    );

    return (
        <div className={cx('ProductGridCell', className)}>
            <div className="ProductGridCell__container">
                <div className="ProductGridCell__top-bar">
                    <ProductBadge badges={badges} />
                </div>
                <ProductGallery
                    className="ProductGridCell__gallery"
                    mainMedia={mainMedia}
                    media={variant.GalleryMedia}
                />
                <h6 className="ProductGridCell__name">{variant.DisplayName}</h6>
                <ProductPrice className="ProductGridCell__price mb-4" quantity={qty} priceInfo={priceInfo} noRanges />
                {isDisplayOnly ? null : stockStatus.hasStock ? (
                    <ProductInput
                        className="ProductGridCell__input"
                        quantity={qty}
                        onQuantityChange={noQty ? null : setQty}
                        onAddToCart={addToCart}
                        currentItemStatus={stockStatus}
                        productInfo={variant}
                        currentChildren={[variant]}
                        isUserAuthenticated={isUserAuthenticated}
                        gtmListValue={gtmListValue}
                        checkoutPageLink={checkoutPageLink}
                        reverseButtonOrder={inputReverseButtonOrder}
                        numInputButtons={inputNumInputButtons}
                        addToCartLabel={addToCartLabel}
                        statusTooltip={inputStatusTooltip}
                        itemCodes={[variant.Code]}
                        noSidePadding
                        noBuyNow
                    />
                ) : (
                    <div className="ProductGridCell__input oos mt-3">
                        <NotifyMe {...notifyProps} code={variant.Code} className="floating bg-none" />
                        <button className="btn btn-secondary w-100 disabled" disabled>
                            <Translation id="Commerce.Product.ProductOutOfStock" />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

ProductGridCell.propTypes = {
    className: px.string,
    variant: Product,
    onAddToCart: px.func,
    gtmListValue: px.string,
    checkoutPageLink: px.string,
    isUserAuthenticated: px.bool,
    inputReverseButtonOrder: px.bool,
    inputNumInputButtons: px.bool,
    resetQtyAfterAdd: px.bool,
    addToCartLabel: px.string,
    NotifyMe: px.elementType,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    noQty: px.bool,
    notifyProps: px.objectOf(px.any),
    defaultQuantity: px.number,
};
