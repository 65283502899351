import { createSelector } from 'reselect';
import { Formatter } from 'Common/utils';
import * as types from './types';
import { getTranslationString } from '../localization/selectors';

export const getCustomerState = (state) => state.customer;

export const getCustomerAddressesState = createSelector(getCustomerState, (state = {}) => state.addresses);

export const getCustomerGiftCardsState = createSelector(getCustomerState, (state = {}) => state.giftCards);

export const getCustomerPaymentMethodsState = createSelector(getCustomerState, (state = {}) => state.paymentMethods);

export const getUserEmail = createSelector(getCustomerState, (state = {}) => state.userEmail);

export const getCustomerError = createSelector(getCustomerState, (state = {}) => state.customerError);

export const getCustomerMessage = createSelector(getCustomerState, (state = {}) => state.customerMessage);

export const getCustomerMessageTimestamp = createSelector(getCustomerMessage, (message = {}) => message.ts);

export const getTranslatedCustomerMessage = createSelector(
    (state) => state,
    getCustomerMessage,
    (state, message = {}) =>
        Formatter.property(getTranslationString(state, message.message) || message.message, message.translationParams)
);

export const getHeaderCustomerMessage = createSelector(getCustomerMessage, (message = {}) =>
    message.position === 'header' ? message : {}
);

export const getTranslatedHeaderCustomerMessage = createSelector(
    (state) => state,
    getHeaderCustomerMessage,
    (state, message = {}) =>
        Formatter.property(getTranslationString(state, message.message) || message.message, message.translationParams)
);

export const getGiftCardCustomerMessage = createSelector(getCustomerMessage, (message = {}) =>
    message?.type === types.ADD_CUSTOMER_GIFT_CARD ? message.message : null
);

export const isLoggedIn = createSelector(getUserEmail, (email = null) => !!email);

export const getCustomerAddresses = createSelector(getCustomerState, (state = {}) => state.addresses);

export const getAddressesWithNoDefaults = createSelector(getCustomerAddressesState, (addresses = []) =>
    addresses.map((address) => ({ ...address, IsDefault: false }))
);

export const getCustomerGiftCards = createSelector(getCustomerState, (state = {}) => state.giftCards);

export const getCustomerPaymentMethods = createSelector(getCustomerState, (state = {}) => state.paymentMethods);

// order
export const getCustomerOrders = createSelector(getCustomerState, (state = {}) => state.customerOrders);

export const getCustomerRmaRequestSuccess = createSelector(
    getCustomerOrders,
    (customerOrders = {}) => customerOrders.rmaRequestSuccess
);

export const getCustomerRmaRequestReference = createSelector(
    getCustomerOrders,
    (customerOrders = {}) => customerOrders.rmaRequestReference
);

export const getCustomerRmaRequestMessage = createSelector(
    getCustomerOrders,
    (customerOrders = {}) => customerOrders.rmaRequestMessage
);

export const getCustomerOrderHistory = createSelector(
    getCustomerOrders,
    (customerOrders = {}) => customerOrders.orders
);

export const getCustomerOrderLastUpdate = createSelector(
    getCustomerOrders,
    (customerOrders = {}) => customerOrders.lastUpdate
);

export const getOrderDetail = createSelector(getCustomerOrderHistory, (orders = []) =>
    orders && orders.length ? orders[0] : null
);

export const getOrderTableData = createSelector(getCustomerOrderHistory, (orders = []) =>
    orders !== null && orders.length && orders[0] !== undefined
        ? orders
              .filter((v) => v !== null)
              .map((order) => ({
                  date: order.Created,
                  orderNumber: order.OrderNumber,
                  total: order.Total,
                  currency: order.Currency,
                  omsOrderReference: order.OMSOrderReference,
                  trackingNumbers: order.Forms.reduce(
                      (acc, form) =>
                          acc.concat(
                              form.Shipments?.reduce(
                                  (shipAcc, shipment) =>
                                      shipment.ShipmentTrackingNumbers?.length
                                          ? shipAcc.concat(
                                                shipment.ShipmentTrackingNumbers.map(
                                                    ({ TrackingNumber, TrackingUrl } = {}) => ({
                                                        number: TrackingNumber,
                                                        url: TrackingUrl,
                                                    })
                                                )
                                            )
                                          : shipAcc,
                                  []
                              )
                          ),
                      []
                  ),
              }))
        : null
);

export const getOrderPagination = createSelector(getCustomerOrders, (customerOrders = {}) => ({
    orderCount: customerOrders.orderCount,
    orderTotal: customerOrders.orderTotal,
    orderPage: customerOrders.orderPage,
}));

//Notification Subscription
export const getNotificationSubscriptionItems = createSelector(
    getCustomerState,
    (customer = {}) => customer.userNotificationSubscriptions
);

// Notifications

export const getNotifications = createSelector(getCustomerState, (state = {}) => state.notifications);
