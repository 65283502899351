import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useViewport, useTranslation } from 'Common/hooks';
import { Url } from 'Common/utils';
import { AccordionTable, NumberInput } from 'Common/components/ui';
import * as PRODUCT from '~config/product';
import ProductPrice from '../../ProductPrice';
import ProductBadge from '../../ProductBadge';

function isQuantityValid(value = 0) {
    const nonZeroPositiveIntegerRegex = /^[1-9][0-9]*$/;

    return nonZeroPositiveIntegerRegex.test(value);
}

export default function ProductBuyInBulk({
    className,
    buyInBulkTableData,
    id,
    onAddItemToCart,
    orderLastUpdate,
    forceMobile = false,
    Price = ProductPrice,
    labelledBy = id ? id.replace(/content/g, 'tab') : undefined,
}) {
    const viewport = useViewport();
    const [isLoading, setIsLoading] = React.useState(false);
    const [rows, setRows] = React.useState({});
    const updateRow = React.useCallback(
        (rowId, state) => {
            setRows({ ...rows, [rowId]: state });
        },
        [rows]
    );

    const addToCartLabel = useTranslation('Form.Button.AddToCart.Label');
    const quantityLabel = useTranslation('Commerce.Product.Tabs.BuyInBulk.Quantity.Label');
    const priceLabel = useTranslation('Commerce.Product.Tabs.Price.Label');
    const typeIdLabel = useTranslation('Commerce.Product.Tabs.TypeId.Label');
    const skuLabel = useTranslation('Commerce.Product.Tabs.Sku.Label');
    const colorLabel = useTranslation('Commerce.Product.Tabs.Color.Label');

    const accordionHeaders = React.useMemo(
        () =>
            forceMobile || viewport.is.mobile
                ? [typeIdLabel, skuLabel]
                : [typeIdLabel, colorLabel, skuLabel, priceLabel, quantityLabel],
        [colorLabel, viewport, priceLabel, quantityLabel, skuLabel, typeIdLabel, forceMobile]
    );

    const onAddCart = React.useCallback(
        (code, quantity, { gtmPayload }) => {
            gtmPayload.ecommerce.add.products[0].quantity = quantity;
            setIsLoading(true);
            onAddItemToCart(code, quantity, { gtmPayload });
        },
        [onAddItemToCart]
    );

    React.useEffect(() => {
        setIsLoading(false);
    }, [orderLastUpdate]);

    const accordionData = React.useMemo(() => {
        return buyInBulkTableData.map((data) => {
            if (forceMobile || viewport.is.mobile) {
                const dropdownData = [
                    {
                        label: colorLabel,
                        values: [
                            {
                                node: data.swatch,
                                id: data.swatch,
                            },
                        ],
                    },
                    {
                        label: priceLabel,
                        values: [
                            {
                                node: <Price priceInfo={data.priceInfo} quantity={rows[data.id]?.quantity} />,
                                id: priceLabel,
                            },
                        ],
                    },
                    {
                        label: quantityLabel,
                        values: [
                            {
                                node: (
                                    <NumberInput
                                        min={1}
                                        step={1}
                                        noDecimal
                                        value={rows[data.id]?.quantity ?? ''}
                                        onChange={(inputValue) =>
                                            updateRow(data.id, {
                                                quantity: inputValue,
                                                disabled: !isQuantityValid(inputValue),
                                            })
                                        }
                                        useStepBtns={false}
                                        disabled={false}
                                    />
                                ),
                                id: quantityLabel,
                            },
                        ],
                    },
                    {
                        label: null,
                        values: [
                            {
                                node: (
                                    <button
                                        disabled={
                                            isLoading ||
                                            !data.hasStock ||
                                            (rows[data.id] ? rows[data.id].disabled : true)
                                        }
                                        onClick={() =>
                                            onAddCart(data.id, rows[data.id]?.quantity, { gtmPayload: data.gtaPayload })
                                        }
                                        className="btn w-100 btn-primary btn-sm"
                                    >
                                        {addToCartLabel}
                                    </button>
                                ),
                                id: 'addToCart',
                            },
                        ],
                    },
                ];

                return {
                    content: dropdownData,
                    id: data.id,
                    rowValues: [
                        {
                            value: (
                                <div className="d-flex align-items-center">
                                    <ProductBadge badges={data.badges} className="mr-2" style={{ fontSize: '.8em' }} />
                                    <img
                                        className="thumbnail mr-3 mr-md-0"
                                        src={Url.addToRelativeUrl(
                                            data.imageUrl,
                                            `format=png&height=49&width=82${
                                                PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                                            }&fuzziness=40`
                                        )}
                                        aria-hidden
                                        alt=""
                                    />
                                </div>
                            ),
                            id: data.imageUrl,
                        },
                        {
                            value: data.id,
                            id: data.id,
                        },
                    ],
                };
            }

            return {
                id: data.id,
                rowValues: [
                    {
                        value: (
                            <div className="d-flex align-items-center">
                                <ProductBadge badges={data.badges} className="mr-2" style={{ fontSize: '.8em' }} />
                                <img
                                    className="thumbnail mr-3 mr-md-0"
                                    src={Url.addToRelativeUrl(
                                        data.imageUrl,
                                        `format=png&height=49&width=82${
                                            PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                                        }&fuzziness=40`
                                    )}
                                    aria-hidden
                                    alt=""
                                />
                            </div>
                        ),
                        id: data.imageUrl,
                    },
                    {
                        value: data.swatch,
                        id: data.swatch,
                    },
                    {
                        value: data.id,
                        id: data.id,
                    },
                    {
                        value: <Price priceInfo={data.priceInfo} quantity={rows[data.id]?.quantity} />,
                        id: 'Commerce.Product.Tabs.Price.Label',
                    },
                    {
                        value: (
                            <NumberInput
                                min={1}
                                step={1}
                                noDecimal
                                value={rows[data.id]?.quantity ?? ''}
                                onChange={(inputValue) =>
                                    updateRow(data.id, {
                                        quantity: inputValue,
                                        disabled: !isQuantityValid(inputValue),
                                    })
                                }
                                useStepBtns={false}
                                disabled={false}
                            />
                        ),
                        id: 'Commerce.Product.Tabs.BuyInBulk.Quantity.Label',
                    },
                    {
                        value: (
                            <button
                                disabled={
                                    isLoading || !data.hasStock || (rows[data.id] ? rows[data.id].disabled : true)
                                }
                                onClick={() =>
                                    onAddCart(data.id, rows[data.id]?.quantity, { gtmPayload: data.gtaPayload })
                                }
                                className="btn btn-primary btn-sm"
                            >
                                {addToCartLabel}
                            </button>
                        ),
                        id: 'Form.Button.AddToCart.Label',
                    },
                ],
            };
        });
    }, [
        buyInBulkTableData,
        viewport,
        rows,
        isLoading,
        addToCartLabel,
        colorLabel,
        priceLabel,
        quantityLabel,
        forceMobile,
        updateRow,
        onAddCart,
    ]);

    return (
        <div
            id={id}
            role="tabpanel"
            aria-labelledby={labelledBy}
            className={cx('ProductBuyInBulk tab-pane fade show table-responsive', className)}
        >
            <AccordionTable accordionData={accordionData} accordionHeaders={accordionHeaders} />
        </div>
    );
}

ProductBuyInBulk.propTypes = {
    className: px.string,
    buyInBulkTableData: px.arrayOf(
        px.shape({
            content: px.arrayOf(
                px.shape({ values: px.arrayOf(px.shape({ node: px.node, id: px.any })), label: px.string })
            ),
            id: px.string,
            imageUrl: px.string,
            priceInfo: px.object,
            hasStock: px.bool,
            gtaPayload: px.object,
        })
    ),
    onAddItemToCart: px.func,
    orderLastUpdate: px.number,
    id: px.string,
    labelledBy: px.string,
    forceMobile: px.bool,
    Price: px.elementType,
};
