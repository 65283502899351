import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { v4 } from 'uuid';
import { Translation } from 'Common/components/localization';

export default function ChildrenSelector({
    className,
    productChildren = [],
    selectedChild = null,
    onSelect,
    skuLabel,
}) {
    const id = React.useRef(`childselector_${v4()}`);
    const [expanded, setExpanded] = React.useState(false);
    const options = React.useMemo(
        () =>
            productChildren.map((c) => ({
                name: c.DisplayName,
                code: c.Code,
                isDisabled: c.StockStatus === 'InStock' ? false : true,
                title: c.StockStatusLabel,
            })),
        [productChildren]
    );

    const onSelectChild = React.useCallback(
        (value) => (onSelect ? onSelect(productChildren.find((c) => c.Code === value)) : null),
        [onSelect, productChildren]
    );

    React.useEffect(() => {
        function onWindowClick() {
            if (expanded) setExpanded(false);
        }

        window.addEventListener('click', onWindowClick);
        return () => window.removeEventListener('click', onWindowClick);
    }, [expanded]);

    return (
        <div className={cx('ChildrenSelector', className)}>
            <div className="container">
                <button
                    className="btn"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#${id.current}`}
                    aria-expanded="false"
                    aria-controls="dropdown-selector"
                    onClick={() => setExpanded(!expanded)}
                >
                    <div className="ChildrenSelector__header row d-flex align-items-center">
                        <p className="paragraph-3 mb-0">
                            <Translation id="Commerce.CatalogAttribute.Variation.FacetLabel" />
                        </p>
                        <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} aria-hidden="true" />
                    </div>
                </button>
                <div className="collapse show px-2 px-md-5" id={id.current}>
                    <div className="child-list px-3">
                        {options.map((option) => (
                            <button
                                key={option.code}
                                className={cx(
                                    'ChildrenSelector__option btn w-100',
                                    selectedChild &&
                                        selectedChild.Code === option.code &&
                                        'selected ChildrenSelector__option--active',
                                    option.isDisabled && 'btn-disabled ChildrenSelector__option--disabled'
                                )}
                                onClick={() => onSelectChild(option.code)}
                                disabled={option.isDisabled}
                                title={option.title}
                            >
                                <p className="paragraph-3 mb-1">
                                    <strong>{option.name}</strong>
                                </p>
                                <p className="paragraph-3 mb-0">
                                    <Translation id={skuLabel} params={{ sku: option.code }} />
                                </p>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

ChildrenSelector.propTypes = {
    className: px.string,
    productChildren: px.arrayOf(px.shape({ DisplayName: px.string, Code: px.string })),
    selectedChild: px.shape({ Code: px.string }),
    onSelect: px.func,
    skuLabel: px.string,
};
