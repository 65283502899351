import * as strings from 'Common/constants/gtm';

export default class GTM {
    static mapEntityToAddCart(entity, quantity = 1, productAttributes, listValue) {
        return {
            event: 'add_to_cart',
            ecommerce: {
                currency: entity.Price.SalePrice.Currency,
                value: entity.Price.SalePrice.Amount * quantity,
                items: [
                    {
                        item_id: entity.Code,
                        item_name: entity.DisplayName,
                        item_brand:
                            productAttributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0] ?? null,
                        item_category:
                            productAttributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0] ?? null,
                        item_category2:
                            productAttributes.find((a) => a.AttributeName === 'ProductFamily')?.Values[0] ?? null,
                        item_category3:
                            productAttributes.find((a) => a.AttributeName === 'ProductSeries')?.Values[0] ?? null,
                        item_list_name: listValue,
                        price: entity.Price.SalePrice.Amount,
                        quantity,
                    },
                ],
            },
        };
    }

    static mapEntityToRemoveCart(products, listValue) {
        return {
            event: 'remove_from_cart',
            ecommerce: {
                currency: products[0].Price.SalePrice.Currency,
                value: products.reduce((sum, product) => sum + product.Price.SalePrice.Amount, 0),
                items: products.map((product) => ({
                    item_id: product.Code,
                    item_name: product.DisplayName,
                    item_brand: product.Attributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0],
                    item_category: product.Attributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0],
                    item_category2: product.Attributes.find((a) => a.AttributeName === 'ProductFamily')?.Values[0],
                    item_category3: product.Attributes.find((a) => a.AttributeName === 'ProductSeries')?.Values[0],
                    item_list_name: listValue,
                    price: product.Price.SalePrice.Amount,
                    quantity: product.Quantity,
                })),
            },
        };
    }

    static mapEntityToImpressions(products, listValue) {
        const firstProduct = products?.length ? products[0] : null;

        return {
            event: 'view_item',
            ecommerce: {
                currency: firstProduct?.Price?.SalePrice?.Currency,
                items: products.map((product) => ({
                    item_id: product.Code,
                    item_name: product.DisplayName,
                    item_brand: product.Attributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0],
                    item_category: product.Attributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0],
                    item_list_name: listValue,
                    price: product.Price.SalePrice.Amount,
                })),
            },
        };
    }

    static mapEntityToImpClick(product, listValue) {
        return {
            event: 'select_item',
            ecommerce: {
                item_list_name: listValue,
                items: [
                    {
                        item_id: product.Code,
                        item_name: product.DisplayName,
                        item_brand: product.Attributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0],
                        item_category: product.Attributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0],
                        price: product.Price.SalePrice.Amount,
                    },
                ],
            },
        };
    }

    static mapEntitiesToCheckoutStep(products, step) {
        return {
            event: step,
            ecommerce: {
                currency: products[0].Price.SalePrice.Currency,
                value: products.reduce((sum, product) => sum + product.Price.SalePrice.Amount, 0),
                items: products.map((product) => ({
                    item_id: product.Code,
                    item_name: product.DisplayName,
                    item_brand: product.Attributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0],
                    item_category: product.Attributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0],
                    item_category2: product.Attributes.find((a) => a.AttributeName === 'ProductFamily')?.Values[0],
                    item_category3: product.Attributes.find((a) => a.AttributeName === 'ProductSeries')?.Values[0],
                    price: product.Price.SalePrice.Amount,
                    quantity: product.Quantity,
                })),
            },
        };
    }

    static getEmailSignup(signupLocation) {
        return {
            event: 'newsletter_signup',
            newslettersignup: {
                location: signupLocation,
            },
        };
    }

    static mapEntityToFormSubmission(formCategory, formName) {
        return {
            event: 'form_submission',
            formCategory,
            formName,
        };
    }

    static updateDataLayer(gtmPayload, layer = strings.LAYERS.ECOMMERCE) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ [layer]: null });
        window.dataLayer.push(gtmPayload);
    }
}
