import * as React from 'react';
import { useSelector } from 'react-redux';
import { CatalogSimpleGrid as Grid } from 'Common/components/catalog';
import { NotificationSubscription } from 'Common/containers/customer';
import { catalog, order } from '~features';
import { useAction } from 'Common/hooks';
import CatalogAltQuickview from './CatalogAltQuickview';

export default function CatalogSimpleGrid(props) {
    const items = useSelector(catalog.selectors.getFilteredProducts);
    const facets = useSelector(catalog.selectors.getFilteredFacets);
    const pageInfo = useSelector(catalog.selectors.getPageInfo);
    const onLoadMore = useAction(catalog.actions.loadMore);
    const onAddItemToCart = useAction(order.actions.addItem);
    const onAddMultipleToCart = useAction(order.actions.addItems);

    return (
        <Grid
            facets={facets}
            NotifyMe={NotificationSubscription}
            onAddItemToCart={onAddItemToCart}
            onAddMultipleToCart={onAddMultipleToCart}
            onLoadMore={onLoadMore}
            pageInfo={pageInfo}
            products={items}
            Quickview={CatalogAltQuickview}
            {...props}
        />
    );
}
