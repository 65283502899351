import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { InfiniteScroll } from 'Common/components/ui';
import CatalogListCard from './CatalogListCard';

export default function CatalogList({
    checkoutPageLink,
    className,
    googleRecaptchaV3Sitekey,
    gtmListValue,
    imgPosition,
    inputStatusTooltip,
    isUserAuthenticated,
    NotifyMe,
    onLoadMore,
    onAddItemToCart,
    onAddMultipleToCart,
    itemStatusTooltip,
    pageInfo = {},
    products = [],
    useRecaptcha,
}) {
    const isDoneLoading = React.useMemo(() => pageInfo.page * pageInfo.pageSize >= pageInfo.total, [pageInfo]);

    return (
        <div className={cx('CatalogList', className)}>
            <InfiniteScroll onLoad={onLoadMore} useLoadIndicator={true} isDoneLoading={isDoneLoading}>
                <div>
                    {products?.map((x, i) => (
                        <CatalogListCard
                            checkoutPageLink={checkoutPageLink}
                            cardIdx={i}
                            gtmListValue={gtmListValue}
                            inputStatusTooltip={inputStatusTooltip}
                            imgPosition={imgPosition}
                            isUserAuthenticated={isUserAuthenticated}
                            key={x.Code}
                            NotifyMe={NotifyMe}
                            notifyProps={{ googleRecaptchaV3Sitekey, useRecaptcha }}
                            onAddItemToCart={onAddItemToCart}
                            onAddMultipleToCart={onAddMultipleToCart}
                            product={x}
                            itemStatusTooltip={itemStatusTooltip}
                        />
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    );
}

CatalogList.propTypes = {
    className: px.string,
    checkoutPageLink: px.string,
    googleRecaptchaV3Sitekey: px.string,
    gtmListValue: px.string,
    imgPosition: px.string,
    inputStatusTooltip: px.oneOfType([px.bool, px.string]),
    isUserAuthenticated: px.bool,
    NotifyMe: px.elementType,
    onAddItemToCart: px.func,
    onAddMultipleToCart: px.func,
    onLoadMore: px.func,
    pageInfo: types.PageInfo,
    products: px.arrayOf(types.Product),
    itemStatusTooltip: px.oneOfType([px.bool, px.string]),
    useRecaptcha: px.bool,
};
