import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { GTM } from 'Common/utils';
import * as GA4 from 'Common/constants/gtm';
import * as types from 'Common/types';
import { Loader } from '../ui';
import { SHIPMENT, PAYMENT, ADDRESS } from 'Common/constants/fields';
import CheckoutEmail from './CheckoutEmail';
import CheckoutShipment from './shippingsection/CheckoutShipment';
import CheckoutPayment from './paymentsection/CheckoutPayment';
import CheckoutComplete from './CheckoutComplete';
import AddressVerificationDrawer from './AddressVerificationDrawer';

function shipmentIsValid(shipment = {}) {
    const {
        [ADDRESS.firstName]: fName,
        [ADDRESS.lastName]: lName,
        [ADDRESS.email]: email,
        [ADDRESS.line1]: line1,
        [ADDRESS.zip]: zip,
        [ADDRESS.city]: city,
        [ADDRESS.state]: state,
        [ADDRESS.country]: country,
        [ADDRESS.phone]: phone,
    } = shipment[SHIPMENT.address] || {};

    const additional = shipment[SHIPMENT.isDigital]
        ? email && country
        : phone && line1 && zip && city && state && country;

    return !!(fName && lName && additional);
}

function emailIsValid(accountEmail) {
    return accountEmail?.length > 5;
}

function paymentIsValid(payments, requiredPaymentAmount) {
    if (requiredPaymentAmount === null || (payments?.length && payments.find((p) => !p.PaymentMethodId))) {
        return false;
    }

    return requiredPaymentAmount === 0 || !!payments.find((p) => p.PaymentType !== 'GiftCard');
}

function determineFocus(accountEmail, shipments, payments, requiredPaymentAmount) {
    if (!emailIsValid(accountEmail)) return 0;
    for (let i = 0, l = shipments.length; i < l; i++) {
        if (!shipmentIsValid(shipments[i])) return i + 1;
    }
    return paymentIsValid(payments, requiredPaymentAmount) ? shipments.length + 2 : shipments.length + 1;
}

export default function CheckoutPage({
    className,
    accountEmail = '',
    isOrdering = false,
    LoaderComponent = Loader,
    AddressVerification = AddressVerificationDrawer,
    CheckoutCompleteSection = CheckoutComplete,
    CheckoutEmailSection = CheckoutEmail,
    CheckoutPaymentSection = CheckoutPayment,
    CheckoutShipmentSection = CheckoutShipment,
    fetchShippingMethods,
    confirmShipment = null,
    currency,
    isLoading,
    isUserAuthenticated,
    loggedIn = false,
    marketId,
    onPlaceOrder,
    requiredPaymentAmount,
    onConfirmShipment,
    onUpdateEmail,
    onUpdatePayment,
    onValidateShipment,
    orderGiftCards,
    orderSummaryData,
    paymentMethods = [],
    payments = [],
    pageTitle = 'checkout',
    promotions,
    removePayment,
    savedShippingAddresses,
    addPromotion,
    removePromotion,
    promoError,
    signinLink = '#',
    cartPageLink = '#',
    signup,
    shipments = [],
    catalogEntities = [],
    unverifiedAddress = null,
    verifiedAddresses = [],
    onCancelVerification,
    siteId,
    useFullBillingAddress = false,
    validateUser,
    useRecaptcha,
}) {
    const [focus, setFocus] = React.useState(-1);
    const [showSpinner, setShowSpinner] = React.useState(isLoading);
    const [editTrack, setEditTrack] = React.useState({});
    const isEditingAny = React.useMemo(() => !!Object.values(editTrack).filter(Boolean).length, [editTrack]);

    const setEditingTracker = React.useCallback(
        (key, value) => () => setEditTrack({ ...editTrack, [key]: value }),
        [editTrack]
    );

    const spinWrapper = React.useCallback(
        (fn) =>
            async (...args) => {
                setShowSpinner(true);
                const succeeded = await fn(...args);

                setShowSpinner(false);

                return succeeded;
            },
        []
    );

    const onConfirmAddress = React.useCallback(
        (address) => {
            setShowSpinner(true);
            return onConfirmShipment(address);
        },
        [onConfirmShipment]
    );

    const endEditWrapper = React.useCallback(
        (key, fn) =>
            async (...args) => {
                const succeeded = await fn(...args);

                if (succeeded) {
                    GTM.updateDataLayer(GTM.mapEntitiesToCheckoutStep(catalogEntities, key));
                    setEditingTracker(key, false)();
                }
                return succeeded;
            },
        [setEditingTracker, catalogEntities]
    );

    const onUpdateShipment = React.useCallback(
        (i) => (focus >= i + 1 ? endEditWrapper(GA4.CHECKOUT_STEPS.SHIPPING, spinWrapper(onValidateShipment)) : null),
        [focus, spinWrapper, onValidateShipment, endEditWrapper]
    );

    const onUpdatePmt = React.useMemo(
        () =>
            focus >= shipments.length + 1 || focus === -1
                ? (form, reCaptchaToken) =>
                      requiredPaymentAmount
                          ? new Promise((res) => {
                                endEditWrapper(GA4.CHECKOUT_STEPS.PAYMENT, spinWrapper(onUpdatePayment))(
                                    form,
                                    reCaptchaToken,
                                    (errors = [], cartState) => {
                                        const paymentError = errors.find((e) => e?.RefType === 'Payment');

                                        if (cartState && paymentError)
                                            res({
                                                errors: [
                                                    {
                                                        message:
                                                            'Commerce.Order.Checkout.Payments.ErrorInvalidPayment.Label',
                                                        params: { type: form[PAYMENT.displayName] },
                                                        attribute: 'invalid-payment',
                                                    },
                                                ],
                                            });
                                        else res({ success: !!cartState });
                                    }
                                );
                            })
                          : Promise.resolve({ success: true })
                : null,
        [focus, shipments, endEditWrapper, spinWrapper, onUpdatePayment, requiredPaymentAmount]
    );

    React.useEffect(() => {
        const next = isLoading ? -1 : determineFocus(accountEmail, shipments, payments, requiredPaymentAmount);

        if (next !== focus) setFocus(next);
    }, [focus, shipments, accountEmail, payments, isLoading, requiredPaymentAmount]);

    React.useEffect(() => {
        if (isLoading) setShowSpinner(true);
        else setShowSpinner(false);
    }, [isLoading]);

    React.useEffect(() => {
        if (confirmShipment) setShowSpinner(false);
    }, [confirmShipment]);

    React.useEffect(() => {
        if (isOrdering) setShowSpinner(true);
        else setShowSpinner(false);
    }, [isOrdering]);

    return (
        <div className={cx('CheckoutPage', className)}>
            {showSpinner ? <LoaderComponent /> : null}
            <h1 className="row mt-5">{pageTitle}</h1>
            {onValidateShipment ? (
                <AddressVerification
                    open={!!confirmShipment}
                    onSelect={endEditWrapper(GA4.CHECKOUT_STEPS.SHIPPING, onConfirmAddress)}
                    onCancel={onCancelVerification}
                    address={unverifiedAddress}
                    verifiedAddresses={verifiedAddresses}
                    backdrop
                />
            ) : null}
            <CheckoutEmailSection
                initialStateIsValid={emailIsValid(accountEmail)}
                signinLink={signinLink}
                loggedIn={loggedIn}
                signup={signup}
                accountEmail={accountEmail}
                onBeginEdit={setEditingTracker(GA4.CHECKOUT_STEPS.CUSTOMER, true)}
                onCancelEdit={setEditingTracker(GA4.CHECKOUT_STEPS.CUSTOMER, false)}
                onUpdate={endEditWrapper(GA4.CHECKOUT_STEPS.CUSTOMER, spinWrapper(onUpdateEmail))}
                forceEdit={focus === 0}
            />
            {shipments.map((shipment, i) => (
                <CheckoutShipmentSection
                    accountEmail={accountEmail}
                    currency={currency}
                    savedShippingAddresses={savedShippingAddresses}
                    forceEdit={focus === i + 1}
                    index={i}
                    initialStateIsValid={shipmentIsValid(shipment)}
                    isUserAuthenticated={isUserAuthenticated}
                    key={shipment.ShipmentId}
                    onBeginEdit={setEditingTracker(GA4.CHECKOUT_STEPS.SHIPPING, true)}
                    onCancelEdit={setEditingTracker(GA4.CHECKOUT_STEPS.SHIPPING, false)}
                    onUpdate={onUpdateShipment(i)}
                    shipment={shipment}
                    showSummary={shipments.length > 1}
                    siteId={siteId}
                    cartPage={cartPageLink}
                    fetchShippingMethods={fetchShippingMethods}
                />
            ))}
            <CheckoutPaymentSection
                accountEmail={accountEmail}
                currency={currency}
                paymentAmount={requiredPaymentAmount}
                removePayment={spinWrapper(removePayment)}
                orderGiftCards={orderGiftCards}
                orderPayments={payments}
                paymentMethods={paymentMethods}
                marketId={marketId}
                onBeginEdit={setEditingTracker(GA4.CHECKOUT_STEPS.PAYMENT, true)}
                onCancelEdit={setEditingTracker(GA4.CHECKOUT_STEPS.PAYMENT, false)}
                initialStateIsValid={paymentIsValid(payments, requiredPaymentAmount)}
                isUserAuthenticated={isUserAuthenticated}
                onUpdate={onUpdatePmt}
                forceEdit={focus === shipments.length + 1}
                addPromotion={addPromotion}
                removePromotion={removePromotion}
                promoError={promoError}
                shipments={shipments}
                siteId={siteId}
                useFullBillingAddress={useFullBillingAddress}
                validateUser={validateUser}
                useRecaptcha={useRecaptcha}
                disabled={!shipments.some((s) => shipmentIsValid(s))}
            />
            <CheckoutCompleteSection
                addPromotion={addPromotion}
                removePromotion={removePromotion}
                promotions={promotions}
                forceEdit={focus === shipments.length + 2}
                onPlaceOrder={onPlaceOrder}
                disableSubmit={isOrdering || isEditingAny}
                currency={currency}
                cartPage={cartPageLink}
                orderSummaryData={orderSummaryData}
                promoError={promoError}
                validateUser={validateUser}
                useRecaptcha={useRecaptcha}
            />
        </div>
    );
}

CheckoutPage.propTypes = {
    addPromotion: px.func,
    promoError: px.shape({ message: px.string, timestamp: px.number, reference: px.string, type: px.string }),
    className: px.string,
    onUpdateEmail: px.func,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    onValidateShipment: px.func,
    onUpdatePayment: px.func,
    cartPageLink: px.string,
    accountEmail: px.string,
    shipments: px.arrayOf(types.Shipment),
    orderSummaryData: types.OrderSummary,
    verifiedAddresses: px.arrayOf(types.Address),
    unverifiedAddress: types.Address,
    CheckoutEmailSection: px.elementType,
    CheckoutShipmentSection: px.elementType,
    AddressVerification: px.elementType,
    paymentMethods: px.arrayOf(px.object),
    LoaderComponent: px.elementType,
    signinLink: px.string,
    pageTitle: px.string,
    onPlaceOrder: px.func,
    loggedIn: px.bool,
    currency: px.string,
    signup: px.bool,
    isOrdering: px.bool,
    siteId: px.string,
    requiredPaymentAmount: px.number,
    CheckoutCompleteSection: px.elementType,
    CheckoutPaymentSection: px.elementType,
    fetchShippingMethods: px.func,
    confirmShipment: px.objectOf(px.any),
    isLoading: px.bool,
    onConfirmShipment: px.func,
    orderGiftCards: px.oneOfType([px.object, px.array]),
    payments: px.arrayOf(px.object),
    promotions: px.oneOfType([px.object, px.array]),
    removePayment: px.func,
    removePromotion: px.func,
    savedShippingAddresses: px.oneOfType([px.object, px.array]),
    onCancelVerification: px.func,
    marketId: px.string,
    useFullBillingAddress: px.bool,
    validateUser: px.func,
    catalogEntities: px.arrayOf(types.Product),
    useRecaptcha: px.bool,
};
