import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import ProductGallery from './ProductGallery';
import { ProductDetailsComponent as ProductDetails } from '~components/product';
import RelatedProducts from './RelatedProducts';

export default function ProductView({
    className,
    Details = ProductDetails,
    detailsProps = {},
    Gallery = ProductGallery,
    galleryProps = {},
    isUserAuthenticated,
    Related = RelatedProducts,
    relatedProductProps = {},
    showRelatedProducts = false,
    itemStatusTooltip,
}) {
    return (
        <div className={cx('ProductView container-fluid px-0 px-md-3', className)}>
            <div className="row">
                <Gallery
                    className={cx('col-12', `col-${galleryProps.desktopBreakpoint ?? 'md'}-5`)}
                    {...galleryProps}
                />
                <Details
                    className={cx('col-12', `col-${detailsProps.desktopBreakpoint ?? 'md'}-7`, {
                        'col-lg-5': showRelatedProducts,
                    })}
                    isUserAuthenticated={isUserAuthenticated}
                    itemStatusTooltip={itemStatusTooltip}
                    {...detailsProps}
                />
                {showRelatedProducts ? <Related {...relatedProductProps} className="d-none d-lg-block col-2" /> : null}
            </div>
        </div>
    );
}

ProductView.propTypes = {
    className: px.string,
    Details: px.elementType,
    detailsProps: px.objectOf(px.any),
    galleryProps: px.objectOf(px.any),
    Gallery: px.elementType,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    Related: px.elementType,
    relatedProductProps: px.object,
    showRelatedProducts: px.bool,
    itemStatusTooltip: px.bool,
};
