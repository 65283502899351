import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { LinkItem } from 'Common/types';
import { TAGS } from 'Common/constants/gtm';
import { ProductListView as ListView } from 'Common/components/product';
import { NotificationSubscription } from 'Common/containers/customer';
import { product, order } from '~features';

export default function ProductListView(props) {
    const onAddItemToCart = useAction(order.actions.addItem);
    const onAddMultipleToCart = useAction(order.actions.addItems);
    const variants = useSelector(product.selectors.getChildren);
    const productInfo = useSelector(product.selectors.getProduct);

    return (
        <ListView
            onAddItemToCart={onAddItemToCart}
            onAddMultipleToCart={onAddMultipleToCart}
            variants={variants}
            productInfo={productInfo}
            gtmListValue={TAGS.PDP}
            NotifyMe={NotificationSubscription}
            {...props}
        />
    );
}

ProductListView.propTypes = {
    Links: px.node,
    storeLocatorLink: LinkItem,
};
