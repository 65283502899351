import * as React from 'react';
import px from 'prop-types';
import { GTM } from 'Common/utils';
import * as GA4 from 'Common/constants/gtm';
import { useSelector } from 'react-redux';
import { logger } from 'Common/core';
import { useAction, useCatalogEntities, useScript } from 'Common/hooks';
import Checkout, { Promotions, GiftCards } from 'Common/components/checkout/MultiPageCheckout';
import { updateOrderEmail, updateShipmentDetail, updateShipmentAddress, addPayment } from 'Common/features/order/api';
import { order } from '~features';
import { NODE_ENV } from '$env';

const cartApiOptions = {
    expand: ['forms', 'ordertotals', 'savedshippingaddresses', 'shippingmethods', 'savedpayments'],
    update: ['lineitems', 'shipping', 'payment', 'promotions'],
};

export default function MultiPageCheckout({
    accountEmail,
    signupLink,
    signinLink,
    signoutLink,
    cartPage,
    siteId,
    googleRecaptchaV3Sitekey,
    orderConfirmationPage,
    homePage,
    darkTheme,
}) {
    const cartLoadCount = React.useRef(0);
    const [cartLoading, setCartLoading] = React.useState(false);

    const startLoader = React.useCallback(() => {
        cartLoadCount.current += 1;
        setCartLoading(true);
    }, []);

    const stopLoader = React.useCallback(() => {
        cartLoadCount.current -= 1;
        if (!cartLoadCount.current) setCartLoading(false);
    }, []);

    const loadedRef = React.useRef(false);
    const loadCart = useAction(order.actions.loadCart, startLoader, stopLoader);
    const forms = useSelector(order.selectors.getCartForms);
    const orderEmail = useSelector(order.selectors.getOrderEmail);
    const isUpdating = useSelector(order.selectors.isCartUpdating);
    const currency = useSelector(order.selectors.getCurrencyCode);
    const summary = useSelector(order.selectors.getCartSummaryData);
    const orderGiftCards = useSelector(order.selectors.getOrderGiftCards);
    const paymentMethods = useSelector(order.selectors.getPaymentMethods);
    const promoError = useSelector(order.selectors.getPromoCodeValidationIssue);
    const promotions = useSelector(order.selectors.getPromoCodes);
    const savedShippingAddresses = useSelector(order.selectors.getSavedShippingAddresses);
    const marketId = useSelector(order.selectors.getMarketId);
    const cartId = useSelector(order.selectors.getCartId);
    const placeOrder = useAction(order.actions.placeOrder, startLoader, stopLoader);
    const addPromotion = useAction(order.actions.addPromotion, startLoader, stopLoader);
    const removePromotion = useAction(order.actions.removePromotion, startLoader, stopLoader);
    const removePayment = useAction(order.actions.removePayment, startLoader, stopLoader);
    const deleteCart = useAction(order.actions.clearCart, startLoader, stopLoader);
    const orderUpdateQueue = useAction(order.actions.orderUpdateQueue, startLoader, stopLoader);
    const getVerifiedAddresses = useAction(order.actions.getVerifiedAddresses, startLoader, stopLoader);
    const lineItems = useSelector(order.selectors.getAllLineItems);
    const catalogEntities = useCatalogEntities(lineItems);

    const savedGiftCards = React.useMemo(
        () => paymentMethods?.find((pm) => pm.SystemName === 'GiftCard')?.SavedPaymentInfo,
        [paymentMethods]
    );

    const promoProps = React.useMemo(
        () => ({
            promotions,
            addPromotion,
            removePromotion,
            promoError,
            Promotions,
        }),
        [promotions, addPromotion, removePromotion, promoError]
    );

    const giftCardProps = React.useMemo(
        () =>
            paymentMethods?.find((pm) => pm.SystemName === 'GiftCard')
                ? {
                      accountEmail,
                      removePayment,
                      savedGiftCards,
                      GiftCards,
                      giftCards: orderGiftCards,
                  }
                : null,
        [accountEmail, removePayment, savedGiftCards, paymentMethods, orderGiftCards]
    );

    const recaptchSrc = React.useMemo(
        () =>
            googleRecaptchaV3Sitekey
                ? `https://www.google.com/recaptcha/api.js?render=${googleRecaptchaV3Sitekey}`
                : null,
        [googleRecaptchaV3Sitekey]
    );

    const grecaptcha = useScript(recaptchSrc, {
        globalNamespace: 'grecaptcha',
        destroyOnDismount: false,
        delay: 100,
    });

    const validateUser = React.useMemo(
        () =>
            googleRecaptchaV3Sitekey
                ? () =>
                      new Promise((res, rej) =>
                          grecaptcha.ready(() => {
                              grecaptcha.execute(googleRecaptchaV3Sitekey, { action: 'submit' }).then(res).catch(rej);
                          })
                      )
                : null,
        [googleRecaptchaV3Sitekey, grecaptcha]
    );

    const handleUpdates = React.useCallback(
        async (updates = []) => {
            let response;
            const queue = await Promise.all(
                updates.map(async ({ type, data }) => {
                    switch (type) {
                        case GA4.CHECKOUT_STEPS.CUSTOMER:
                            return [updateOrderEmail, data.email, data.signup];
                        case 'address':
                            return [
                                updateShipmentAddress,
                                data.shipmentId,
                                data.address,
                                data.useDefault,
                                {
                                    expand: ['ordertotals', 'forms', 'shippingmethods'],
                                    update: ['shipping', 'lineitems', 'promotions', 'payment'],
                                },
                            ];
                        case GA4.CHECKOUT_STEPS.SHIPPING:
                            return [
                                updateShipmentDetail,
                                data,
                                {
                                    expand: ['ordertotals', 'forms', 'shippingmethods'],
                                    update: ['shipping', 'lineitems', 'promotions', 'payment'],
                                },
                            ];
                        case GA4.CHECKOUT_STEPS.PAYMENT:
                            return [
                                addPayment,
                                data,
                                (validateUser && (await validateUser())) || undefined,
                                { expand: ['ordertotals', 'forms'] },
                            ];
                        case 'giftcard':
                            return [addPayment, data, undefined, { expand: ['ordertotals', 'forms'] }];
                        default:
                            return null;
                    }
                })
            );

            try {
                startLoader();
                response = await new Promise((res) =>
                    orderUpdateQueue(queue.filter(Boolean), undefined, (errors, data) =>
                        res({ success: Boolean(!errors?.length && data), errors, data })
                    )
                );
            } finally {
                stopLoader();
            }

            if (response.success) {
                updates.forEach((update) => {
                    GTM.updateDataLayer(GTM.mapEntitiesToCheckoutStep(catalogEntities, update.type));
                });
                return response.data;
            }

            const error = Array.isArray(response.errors) ? response.errors[0] : response.errors;

            throw new Error(error?.Issue || error?.message || error);
        },
        [validateUser, startLoader, orderUpdateQueue, stopLoader, catalogEntities]
    );

    const onPlaceOrder = React.useCallback(async () => {
        let token = undefined;

        if (validateUser) token = await validateUser();
        return new Promise((res, rej) => {
            placeOrder(orderConfirmationPage, token, cartId, (err, data) => (err ? rej(err) : res(data)));
        });
    }, [orderConfirmationPage, placeOrder, validateUser, cartId]);

    React.useEffect(() => {
        if (!loadedRef.current && !isUpdating) {
            loadedRef.current = true;
            loadCart(cartApiOptions);
        }
    }, [loadCart, isUpdating]);

    React.useEffect(() => {
        if (NODE_ENV.startsWith('dev') && !Object.prototype.hasOwnProperty.call(window, 'DELETE_CART')) {
            window.DELETE_CART = deleteCart;
            logger.debug('You may use DELETE_CART() to clear your cart while in development');
        }
    }, [deleteCart]);

    return (
        <Checkout
            cartLoaded={
                !cartLoading &&
                forms?.length &&
                savedShippingAddresses != null &&
                paymentMethods?.[0]?.SavedPaymentInfo != null
            }
            cartLoading={!forms?.length || cartLoading}
            accountEmail={accountEmail}
            isLoggedIn={!!accountEmail}
            orderEmail={orderEmail}
            promoProps={promoProps}
            giftCardProps={giftCardProps}
            summaryData={summary}
            forms={forms}
            currency={currency}
            takeoverPage
            siteId={siteId}
            signinLink={signinLink}
            signupLink={signupLink}
            signoutLink={signoutLink}
            getVerifiedAddresses={getVerifiedAddresses}
            handleUpdates={handleUpdates}
            savedShippingAddresses={savedShippingAddresses}
            onPlaceOrder={onPlaceOrder}
            marketId={marketId}
            homePage={homePage}
            cartId={cartId}
            redirectWhenEmpty={cartPage || homePage}
            darkTheme={darkTheme}
        />
    );
}

MultiPageCheckout.propTypes = {
    signinLink: px.string,
    signupLink: px.string,
    signoutLink: px.string,
    accountEmail: px.string,
    cartPage: px.string,
    siteId: px.string,
    googleRecaptchaV3Sitekey: px.string,
    orderConfirmationPage: px.string,
    homePage: px.string,
    darkTheme: px.string,
};
