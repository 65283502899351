import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { ProductRecommendations as Recommendations } from 'Common/components/product';
import { useAction } from 'Common/hooks';
import { RecommendedProductCard as ProductCard, ProductPartialView } from '~components/product';
import { product } from '~features';
import { Translation } from 'Common/components/localization';

export default function ProductRecommendations({ className, isUserAuthenticated, checkoutPageLink, ...props }) {
    const trackProductQuickviewClick = useAction(product.actions.trackProductQuickviewClick);
    const products = useSelector(product.selectors.getEpiRecommedationCrossSellProducts);

    return products?.length ? (
        <>
            <h6 className="mb-4">
                <Translation id="Commerce.Product.Recommended.Products.Title" />
            </h6>
            <Recommendations
                className={className}
                checkoutPageLink={checkoutPageLink}
                isUserAuthenticated={isUserAuthenticated === 'True'}
                ProductPartialView={ProductPartialView}
                products={products}
                RecommendedProductCard={ProductCard}
                trackProductQuickviewClick={trackProductQuickviewClick}
                {...props}
            />
        </>
    ) : null;
}

ProductRecommendations.propTypes = {
    className: px.string,
    checkoutPageLink: px.string,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
};
