import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { useTranslation, useProductDetails, useCatalogEntity } from 'Common/hooks';
import { Icon, Badge, Loader } from 'Common/components/ui';
import { ProductPrice, ProductBadge, ProductGallery, ProductInput } from 'Common/components/product';

export default function CartItem({
    className,
    item,
    qty = 1,
    gtmListValue,
    onChangeQty,
    onDelete,
    onAdd,
    onEdit,
    inputStatusTooltip = 'top',
    allowZeroQty = false,
    disabled = false,
    clickable = false,
}) {
    const lblRemove = useTranslation('Commerce.Order.Cart.RemoveItem.Label');
    const [productInfo, variantInfo] = useCatalogEntity(item.ParentCode, item.Code);

    const itemSalePrice = React.useMemo(
        () => (item.SalePrice != undefined ? item.SalePrice.toFixed(2) : item?.Price?.SalePrice?.Amount),
        [item]
    );

    const salePrice = React.useMemo(() => {
        const itemSalePriceNum = parseFloat(itemSalePrice);
        const variantSalePriceNum = parseFloat(variantInfo?.Price?.SalePrice?.Amount?.toFixed(2));

        return !isNaN(itemSalePriceNum) && !isNaN(variantSalePriceNum) && itemSalePriceNum < variantSalePriceNum
            ? itemSalePriceNum
            : null;
    }, [itemSalePrice, variantInfo]);

    const { badges, mainMediaImage, priceInfo, stockStatus, attributes } = useProductDetails(variantInfo, productInfo, {
        useFacets: true,
        salePrice: salePrice,
    });

    const onEditItem = React.useCallback(() => onEdit(productInfo), [onEdit, productInfo]);

    const defaultPrice = React.useMemo(() => item.ListPriceFormatted ?? item.SalePriceFormatted ?? '', [item]);

    const onImageClick = React.useCallback(() => {
        if (clickable) window.location.href = productInfo?.ContentUrl;
    }, [productInfo, clickable]);

    return (
        <div className={cx('CartItem', { editable: !!onEdit, disabled, adjustable: !!onChangeQty }, className)}>
            <div className="CartItem__body">
                <div className="CartItem__col left">
                    {productInfo ? (
                        <ProductGallery
                            className={cx('CartItem__gallery', { disabled: !clickable })}
                            mainMedia={mainMediaImage}
                            onMainImageClick={onImageClick}
                        >
                            {onChangeQty || !qty ? null : <Badge>{qty}</Badge>}
                        </ProductGallery>
                    ) : (
                        <Loader />
                    )}
                </div>
                <div className="CartItem__col right">
                    {onDelete ? (
                        <button
                            className="btn btn-del"
                            title={lblRemove}
                            aria-label={lblRemove}
                            onClick={onDelete}
                            disabled={disabled}
                        >
                            <Icon name="times" />
                        </button>
                    ) : null}
                    <ProductBadge badges={badges} />
                    <a className={cx('CartItem__name', { disabled: !clickable })} href={productInfo?.ContentUrl}>
                        {item.DisplayName ?? variantInfo?.DisplayName ?? productInfo?.DisplayName}
                    </a>
                    <div className="CartItem__table">
                        <table>
                            <tbody>
                                {attributes.map((a) => (
                                    <tr key={`cart_item_${item.Code}-${a.name}`}>
                                        <th>{a.name}:</th>
                                        <td>{a.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {variantInfo && (onAdd || onEdit) ? (
                        <ProductPrice
                            className="CartItem__price mb-4"
                            quantity={qty}
                            priceInfo={priceInfo}
                            defaultPrice={defaultPrice}
                            noRanges
                        />
                    ) : variantInfo && onChangeQty ? (
                        <ProductInput
                            className="CartItem__input"
                            quantity={qty}
                            onQuantityChange={onChangeQty}
                            currentItemStatus={stockStatus}
                            productInfo={variantInfo}
                            currentChildren={[variantInfo]}
                            disabled={item.IsGift || disabled}
                            statusTooltip={inputStatusTooltip}
                            itemCodes={[variantInfo?.Code]}
                            noSidePadding
                            numInputButtons
                        >
                            <ProductPrice
                                className="CartItem__price mb-4"
                                quantity={qty}
                                priceInfo={priceInfo}
                                defaultPrice={defaultPrice}
                                multiplyByQuantity
                                noRanges
                            />
                        </ProductInput>
                    ) : variantInfo ? (
                        <ProductPrice
                            className="CartItem__price mb-4"
                            quantity={qty}
                            priceInfo={priceInfo}
                            defaultPrice={defaultPrice}
                            multiplyByQuantity
                            noRanges
                        />
                    ) : null}
                </div>
            </div>
            <div className="cartItem__footer">
                {variantInfo && (onAdd || onEdit) ? (
                    <ProductInput
                        className="CartItem__input"
                        quantity={qty}
                        onQuantityChange={onChangeQty}
                        onAddToCart={onAdd ?? onEditItem}
                        addToCartLabel={onAdd ? undefined : 'Commerce.Order.OutOfStock.ChooseReplacement.Label'}
                        currentItemStatus={stockStatus}
                        productInfo={variantInfo}
                        currentChildren={[variantInfo]}
                        gtmListValue={gtmListValue}
                        hideWhenOOS={!!onAdd}
                        disabled={item.IsGift || disabled}
                        allowZeroQty={allowZeroQty}
                        itemCodes={[variantInfo?.Code]}
                        statusTooltip={inputStatusTooltip}
                        noBuyNow
                        noSidePadding
                        numInputButtons
                    />
                ) : null}

                {item.ListPrice > item.SalePrice ? (
                    <div className="CartItem__additional-info">
                        {item.AdditionalInfo
                            ? item.AdditionalInfo.map((info) => (
                                  <div key={info}>
                                      <p className="mb-0">{info}</p>
                                  </div>
                              ))
                            : null}
                    </div>
                ) : null}

                {item.ValidationMessage ? (
                    <div className="CartItem__alert alert alert-danger mt-3 mb-0">{item.ValidationMessage}</div>
                ) : null}
            </div>
        </div>
    );
}

CartItem.propTypes = {
    className: px.string,
    item: types.CartLineItem,
    qty: px.number,
    onChangeQty: px.func,
    onDelete: px.func,
    onEdit: px.func,
    onAdd: px.func,
    gtmListValue: px.string,
    disabled: px.bool,
    allowZeroQty: px.bool,
    inputStatusTooltip: px.oneOfType([px.string, px.bool]),
    clickable: px.bool,
};
