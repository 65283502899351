import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Product } from 'Common/types';
import ProductBadge from '../../ProductBadge';
import ProductInput from '../../ProductInput';

export default function ProductTable({
    addToCartLabel,
    className,
    gtmListValue,
    isLoading,
    isUserAuthenticated,
    itemStatusTooltip,
    onAddItemToCart,
    productInfo,
    tableData,
}) {
    return (
        <div className={cx('ProductTable Table', className)}>
            <table className="table table-sm">
                <thead>
                    <tr>
                        {tableData.columns.map((column) => (
                            <th key={column} scope="col align-top">
                                <span className="subheader d-block">{column}</span>
                            </th>
                        ))}
                        {onAddItemToCart ? <th scope="col align-top"></th> : null}
                    </tr>
                </thead>
                <tbody>
                    {tableData.rows.map((row) => (
                        <React.Fragment key={row.label}>
                            <tr key={row.label}>
                                <th scope="row">
                                    <ProductBadge badges={row.badges} noTopMargin marginRight="1" />
                                    <p className="paragraph-2">{row.label}</p>
                                </th>
                                {row.values.map((cell) => (
                                    <td key={cell.id}>
                                        {cell.values.map((cellVal, index) => (
                                            <p key={cellVal} className="paragraph-2 d-inline">
                                                {cellVal}
                                                {index < cell.values.length - 1 ? ', ' : null}
                                            </p>
                                        ))}
                                    </td>
                                ))}
                                {onAddItemToCart ? (
                                    <td>
                                        <ProductInput
                                            addToCartLabel={addToCartLabel}
                                            currentChildren={[{}]}
                                            currentItemStatus={{ hasStock: row.hasStock, code: row.label }}
                                            disabled={isLoading || !row.hasStock}
                                            gtmListValue={gtmListValue}
                                            isUserAuthenticated={isUserAuthenticated}
                                            onAddToCart={onAddItemToCart}
                                            productInfo={productInfo}
                                            statusTooltip={itemStatusTooltip ? 'left' : null}
                                            quantity={1}
                                        />
                                    </td>
                                ) : null}
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

ProductTable.propTypes = {
    addToCartLabel: px.string,
    className: px.string,
    gtmListValue: px.string,
    isLoading: px.bool,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    itemStatusTooltip: px.oneOf([false, true, undefined, null, 'top', 'bottom']),
    onAddItemToCart: px.func,
    productInfo: Product,
    tableData: px.shape({
        columns: px.arrayOf(px.string),
        rows: px.arrayOf(
            px.shape({
                values: px.arrayOf(
                    px.shape({
                        id: px.string,
                        values: px.arrayOf(px.string),
                        hasStock: px.bool,
                        gtaPayload: px.object,
                    })
                ),
                label: px.string,
            })
        ),
    }),
};
